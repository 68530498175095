import _ from 'lodash';
import base64Img from 'base64-img';
import { sendEmailService } from 'services/email';
import { searchUsersService } from 'services/user';
import { store } from 'redux/store';
import jwt_decode from "jwt-decode";
import { checkLicenseKeyService } from 'services/settings';
import { Parser } from 'json2csv';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/**
 * Increases or decreases the brightness of a color by a percentage of the current brightness.
 *
 * @param   string  hexCode        Supported formats: `#FFF`, `#FFFFFF`, `FFF`, `FFFFFF`
 * @param   float   adjustPercent  A number between -1 and 1. E.g. 0.3 = 30% lighter; -0.4 = 40% darker.
 *
 * @return  string
 */

export function adjustColorBrightness(hexCode = "#FFC540", adjustPercent) {
    hexCode = hexCode.replace("#", "");

    if (hexCode.length === 3) {
        hexCode = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
    }

    const hexCodeChunks = _.chunk(hexCode, 2);
    const hexCodeDecimal = hexCodeChunks.map(code => hexdec(code));

    const newHexCodes = hexCodeDecimal.map(decimalCode => {
        const adjustableLimit = adjustPercent < 0 ? decimalCode : 255 - decimalCode;
        const adjustAmount = Math.ceil(adjustableLimit * adjustPercent);
        return dechex(decimalCode + adjustAmount);
    });

    return `#${newHexCodes.join('')}`;
}

function hexdec(hexString) {
    hexString = (hexString + '').replace(/[^a-f0-9]/gi, '')
    return parseInt(hexString, 16)
}

function dechex(number) {
    if (number < 0) number = 0xFFFFFFFF + number + 1
    const hexValue = parseInt(number, 10).toString(16);
    return hexValue.length === 1 ? `0${hexValue}` : hexValue;
}

export const urlToBase64 = url => {
    return new Promise((resolve, reject) => {
        base64Img.requestBase64(url, function (err, res, body) {
            if (err) reject(err);
            resolve(body.replace(" charset=UTF-8;", ""));
        });
    })
}

export const countChar = (str, ch) => _.countBy(str)[ch] || 0;

export const isOrderHigher = (oldOrder, newOrder) => {
    const oldParts = oldOrder.split('.');
    const newParts = newOrder.split('.');

    for (var i = 0; i < newParts.length; i++) {
        const a = ~~newParts[i]
        const b = ~~oldParts[i]
        if (a > b) return 1
        if (a < b) return -1
    }

    return 0
}

export const changeOrder = (value, increment, operation) => {
    const valueTokens = value.split(".").map(token => parseInt(token));
    const incrementTokens = increment.split(".").map(token => parseInt(token));

    const newVersion = valueTokens.map((valueToken, i) => {
        const newToken = operation === "add" ? valueToken + incrementTokens[i] : valueToken - incrementTokens[i];
        return newToken;
    });

    return newVersion.join(".")
}

export const fileStatusList = [
    {
        id: "in-validation",
        icon: require('assets/icons/chart/chart-icon-7.png').default,
        label: "analysis_tab1_graphen_legend_5"
    },
    {
        id: "pending-approval",
        icon: require('assets/icons/chart/chart-icon-2.png').default,
        label: "analysis_tab1_graphen_legend_6"
    },
    {
        id: "euc",
        icon: require('assets/icons/chart/chart-icon-6.png').default,
        label: "analysis_tab1_graphen_legend_2"
    },
    {
        id: "non-euc",
        icon: require('assets/icons/chart/chart-icon-3.png').default,
        label: "analysis_tab1_graphen_legend_1"
    },
    {
        id: "no-validation",
        icon: require('assets/icons/chart/chart-icon-4.png').default,
        label: "analysis_tab1_graphen_legend_3"
    },
    {
        id: "duplicate",
        icon: require('assets/icons/chart/chart-icon-dup.png').default,
        label: "analysis_tab1_graphen_legend_4"
    },
    {
        id: "in-inventory",
        icon: require('assets/icons/chart/chart-icon-1.png').default,
        label: "analysis_tab1_graphen_legend_7"
    },
    {
        id: "euc-in-inventory",
        icon: require('assets/icons/chart/chart-icon-8.png').default,
        label: "analysis_tab1_graphen_legend_8"
    },
]

export const getFileStatus = (validationStatus, validationResult, inventoryStatus) => {
    const validationStatusLc = validationStatus?.toLowerCase();
    const validationResultLc = validationResult?.toLowerCase();
    const inventoryStatusLc = inventoryStatus?.toLowerCase();

    if (validationStatusLc) {
        if (validationStatusLc === "open")
            return fileStatusList.filter(file => file.id === "in-validation")[0];

        if (validationStatusLc === "pendingacceptance")
            return fileStatusList.filter(file => file.id === "pending-approval")[0];


        if (validationStatusLc === "closed") {
            if (validationResultLc) {
                if (validationResultLc === "euc")
                    return fileStatusList.filter(file => file.id === "euc")[0];

                if (validationResultLc === "non-euc")
                    return fileStatusList.filter(file => file.id === "non-euc")[0];
            }
        }

        if (validationStatusLc === "inventorized") {
            if (["open", "requested", "pendingacceptance"].includes(inventoryStatusLc)) return fileStatusList.filter(file => file.id === "in-inventory")[0];
            if (inventoryStatusLc === "closed") return fileStatusList.filter(file => file.id === "euc-in-inventory")[0];
        }
    }

    return fileStatusList.filter(file => file.id === "no-validation")[0];
}

export const getInventoryStatus = (inventoryFormStatus, inventorySection1Status, inventorySection2Status) => {
    const inventoryFormStatusLc = inventoryFormStatus?.toLowerCase();
    const inventorySection1StatusLc = inventorySection1Status?.toLowerCase();
    const inventorySection2StatusLc = inventorySection2Status?.toLowerCase();

    if (inventoryFormStatusLc === 'requested') return "inventory_status_in_creation"

    if (inventoryFormStatusLc === 'open') {
        if (inventorySection1StatusLc === 'closed' && inventorySection2StatusLc === 'open') return "inventory_status_general_review";
        if (inventorySection1StatusLc === 'open' && (inventorySection2StatusLc === 'waitingprevious' || inventorySection2StatusLc === 'waitinganswer')) return "inventory_status_risk_fill";
    }

    if (inventoryFormStatusLc === 'pendingacceptance') return "inventory_status_validator_review"
    if (inventoryFormStatusLc === 'closed') return "inventory_status_active_validation";

    return "-";
}

export const languageCodes = {
    1: 'Deutsch',
    2: 'English'
}

export const parseFileSnapshot = data => {
    const status = getFileStatus(data.validationStatus, data.validationResult, data.inventoryStatus);

    return {
        id: data.id,
        validationId: data.validationFormId,
        fileSnapshotId: data.fileSnapshotId,
        x: data.complexity,
        y: data.criticality,
        complexity: data.complexity,
        criticality: data.criticality,
        fileName: data.name,
        alias: data.alias,
        path: data.fullName,
        extension: data.extension,
        status: status?.label || "analysis_tab1_graphen_legend_3",
        statusIcon: status?.icon || require('assets/icons/chart/chart-icon-4.png'),
        statusId: status?.id || "no-validation",
        department: "Finance",
        createdVia: data.origin,
        user: data.lastModifiedByMeta,
        createdAt: data.enteredOn,
        lastModified: data.lastModified,
        date: data.lastScan,
        orgDepartmentId: data.orgDepartmentId,
        orgGroupId: data.orgGroupId,
        fileSize: data.fileSize,
        hasError: data.hasError,
        displayName: data.displayName
    }
}

export const getPointData = data => {
    return {
        id: data.id,
        fileSnapshotId: data.fileSnapshotId,
        validationId: data.validationId,
        fileName: data.fileName,
        statusId: data.statusId,
        status: data.status,
        alias: data.alias,
        path: data.path,
        extension: data.extension,
        complexity: data.x,
        criticality: data.y,
        department: data.department,
        createdVia: data.createdVia,
        user: data.user,
        createdAt: data.createdAt,
        lastModified: data.lastModified,
        date: data.date,
        orgDepartmentId: data.orgDepartmentId,
        orgGroupId: data.orgGroupId,
        fileSize: data.fileSize,
        displayName: data.displayName
    }
}

export const parseFileOverviewModalMetadata = data => {
    const getEucStatus = status => {
        if (status === 'euc') return "EUC";
        if (status === 'non-euc') return "Non-EUC";
        return status;
    }

    return {
        id: data.fileId,
        fileSnapshotId: data.fileSnapshotId,
        fileName: data.fileName,
        path: data.inventoryId ? data.fileFullName : data.fullName,
        complexity: data.complexity,
        criticality: data.criticality,
        department: data.department,
        createdVia: data.origin,
        user: data.displayName || data.samAccount,
        createdAt: data.createdAt,
        lastModified: null,
        answers: data.answers,
        alias: data.alias,
        status: data.inventoryId ? data.status : getEucStatus(data.eucResult),
        // Used in QuestionsValidationModal
        type: data.type,
        directoryId: data.directoryId,
        validationId: data.validationId,
        // Used in FileOverviewModal (inventory),
        inventoryId: data.inventoryId,
        sections: data.sections,
        businessOfficer: data.businessOfficer,
        riskOfficer: data.riskOfficer,
        version: data.version,
        // Used in FileOverviewModal (validation),
        displayName: data.displayName
    }
}

export const sendEmail = async (emailType, samAccount) => {
    const state = store.getState();
    const language = state.settingsReducer.settings.language;

    try {
        const users = await searchUsersService(`search=${samAccount}`);
        const userReceiver = users[0];

        if (!userReceiver?.emailAddress) {
            throw new Error("E-mail address not found");
        } else {
            const data = {
                emailType,
                langId: language === 'en' ? 2 : 1,
                receiver: userReceiver?.emailAddress
            };

            await sendEmailService(data);
        }
    } catch (err) {
        console.log(`Error while sending email: ${err}`);
        throw err;
    }
}

export const generatePages = (c, m) => {
    var current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

export const groupByMulti = (array, f) => {
    var groups = {};

    array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });

    return Object.keys(groups).map(function (group) {
        return groups[group];
    })
}

const checkLicenseStatus = async () => {
    try {
        const licenseData = await checkLicenseKeyService();
        return {
            status: true,
            message: licenseData.message,
            useLicense: !licenseData.inactive
        };
    } catch (err) {
        return {
            status: false,
            message: err.message,
            useLicense: !err.inactive
        };
    }
}

export const parseLicenseData = async license => {
    try {
        let licenseParsed = {};
        let resources = [];
        let isInvalid = false;
        let isExpired = false;
        let statusMessage = "Valid";
        const licenseData = await checkLicenseStatus();

        if (licenseData.status) {
            if (!licenseData.useLicense) {
                resources = ['Info-Portal', 'Discovery', 'Analyse', 'Validation', 'Management', 'Settings'];
                isInvalid = false;
                isExpired = false;
                statusMessage = "Valid";
            } else {
                licenseParsed = jwt_decode(license.keyText);

                const licenseExpireEpoch = +new Date(licenseParsed.exp * 1000);
                const nowEpoch = +new Date();

                isExpired = nowEpoch > licenseExpireEpoch;
                statusMessage = isExpired ? "Expired" : "Valid";
            }
        } else {
            isInvalid = true;
            statusMessage = "Invalid";
        }

        return {
            resources,
            ...licenseParsed,
            isExpired,
            isInvalid,
            statusMessage
        }
    } catch (err) {
        return {
            isInvalid: true,
            statusMessage: "Invalid"
        }
    }
}

export const formatFileSize = (numBytes) => {
    const numberInKb = numBytes / 1024;

    if (numberInKb < 1) return "< 1"

    return formatThousandsAndDecimals(numberInKb, true);
}

export const formatThousandsAndDecimals = (number, includeDecimals) => {
    const newString = includeDecimals ? number.toFixed(2).replace(".", ",") : number.toString();
    return newString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
}

export const exportToCsv = (data, filename) => {
    const parser = new Parser({ fields: _.keys(data[0]) }); // Array of header
    const csv = parser.parse(data); // Data as json

    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = filename;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}

export const exportToXlsx = (csvData, filename) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    let ws, wb;

    if (typeof csvData === 'object') {
        const sheetNames = _.keys(csvData);
        let sheetData = {};

        sheetNames.forEach(sheetName => {
            const tabData = XLSX.utils.json_to_sheet(csvData[sheetName]);
            sheetData[sheetName] = tabData;
        })

        wb = {
            Sheets: sheetData,
            SheetNames: sheetNames
        };
    } else {
        ws = XLSX.utils.json_to_sheet(csvData);
        wb = {
            Sheets: {
                'data': ws
            },
            SheetNames: ['data']
        };
    }

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
}

export const getOrganizationName = (useGroups, organizationData, fileData) => {
    const groups = organizationData.organization.map(tenant => tenant.groups).flat(1);
    const departments = groups.map(group => group.departments).flat(1);
    let name;

    if (useGroups) {
        const groupData = groups.filter(group => group.groupId === fileData.orgGroupId)[0];
        name = groupData?.name;
    } else {
        const groupData = departments.filter(department => department.departmentId === fileData.orgDepartmentId)[0];
        name = groupData?.name;
    }

    return name;
}