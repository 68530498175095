import React, { useState, useEffect } from "react";
import MeineValidierungenComponent from '../components/MeineValidierungenComponent';
import { getExcelDataService, forwardValidationService } from 'services/excel';
import { getInventoryDataService, rejectInventoryService, forwardInventoryService } from 'services/inventory';
import { connect } from "react-redux";
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'
import { useTranslation } from "react-i18next";
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import { sendEmail, getInventoryStatus } from 'services/helper';
import _ from 'lodash';

const MeineValidierungenContainer = (props) => {
    const [loading, setLoading] = useState(false);

    const [validationFormOpen, setValidationFormOpen] = useState([]);
    const [currentValidationFormOpen, setCurrentValidationFormOpen] = useState([]);

    const [validationFormClosed, setValidationFormClosed] = useState([]);
    const [currentValidationFormClosed, setCurrentValidationFormClosed] = useState([]);

    const [selectedTab, setSelectedTab] = useState(0);

    const [isSearchUserModalOpen, setIsSearchUserModalOpen] = useState(false);

    const [pagination, setPagination] = useState({
        limit: 100,
        selectValue: 100,
        currentIndex: 1,
    });

    const { t } = useTranslation();

    useEffect(() => {
        getValidationFormByStatus('open', ['Open', 'Started']);
        getValidationFormByStatus(['closed', 'PendingAcceptance'], ['PendingValidation', 'WaitingPrevious', 'WaitingAnswer', 'Closed']);
    }, []);

    const getValidationFormByStatus = async (eucStatuses, inventoryStatuses) => {
        setLoading(true);

        try {
            const response = await getExcelDataService({ validationstatus: eucStatuses }, true);
            const responseInventory = await getInventoryDataService({ inventorystatus: inventoryStatuses }, true);
            const responseInventoryParsed = responseInventory.map(inventory => {
                const inventoryStatus = getInventoryStatus(inventory.inventoryFormStatus, inventory.inventorySection1Status, inventory.inventorySection2Status);
                return {
                    ...inventory,
                    inventoryStatus
                }
            })

            const allResponses = [...response, ...responseInventoryParsed];
            const responseSorted = _.orderBy(allResponses, (item) => +new Date(item.createdAt), ['desc']);

            if (eucStatuses === 'open') {
                setValidationFormOpen(responseSorted);
                changePageData(pagination, responseSorted, 0);
            }

            if (eucStatuses.includes('closed')) {
                setValidationFormClosed(responseSorted);
                changePageData(pagination, responseSorted, 1);
            }
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setLoading(false)
    }

    const rejectInventoryForm = async (inventoryId) => {
        if (await confirm({
            title: t('reject_inventory_confirm_title'),
            confirmation: t('reject_inventory_confirm_message'),
        })) {
            setLoading(true);

            try {
                await rejectInventoryService(inventoryId);
                await reloadTabs();
            } catch (err) {
                console.log(`Error while rejecting inventory form: ${err}`);
            }

            setLoading(false);
        }
    }

    const reloadTabs = async () => {
        await getValidationFormByStatus('open', ['Open', 'Started']);
        await getValidationFormByStatus(['closed', 'PendingAcceptance'], ['PendingValidation', 'WaitingPrevious', 'Closed']);
    }

    const changePageData = (newPagination, data, selectedTab) => {
        setPagination(newPagination);

        const firstIndex = newPagination.limit * (newPagination.currentIndex - 1);
        const lastIndex = firstIndex + newPagination.limit;

        if (selectedTab === 0) {
            const allData = data ? data : validationFormOpen;
            const newPaginatedData = allData.slice(firstIndex, lastIndex);
            setCurrentValidationFormOpen(newPaginatedData);
        }

        if (selectedTab === 1) {
            const allData = data ? data : validationFormClosed;
            const newPaginatedData = allData.slice(firstIndex, lastIndex);
            setCurrentValidationFormClosed(newPaginatedData);
        }
    }

    const changeTab = (tab) => {
        setSelectedTab(tab);
        setPagination({
            ...pagination,
            currentIndex: 1
        })
    }

    const forwardToUser = async (user, entityId, type) => {
        setLoading(true);

        try {
            if (type === "inventory") {
                await forwardInventoryService(user.samAccountName, entityId);
            } else {
                await forwardValidationService(user.samAccountName, entityId);
            }

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_forward_form')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            console.log(`Error while forwarding to user: ${err}`);
        }

        await reloadTabs();

        try {
            await sendEmail("1", user.samAccountName);
        } catch (err) {
            console.log(`Error while sending e-mail to user: ${err}`);
        }

        setLoading(false);
    }

    return (
        <MeineValidierungenComponent
            {...props}
            loading={loading}
            validationFormOpen={validationFormOpen}
            currentValidationFormOpen={currentValidationFormOpen}
            validationFormClosed={validationFormClosed}
            currentValidationFormClosed={currentValidationFormClosed}
            reloadTabs={reloadTabs}
            pagination={pagination}
            changePageData={changePageData}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            changeTab={changeTab}
            rejectInventoryForm={rejectInventoryForm}
            forwardToUser={forwardToUser}
            setIsSearchUserModalOpen={setIsSearchUserModalOpen}
            isSearchUserModalOpen={isSearchUserModalOpen}
        />
    )
};

const mapStateToProps = (store) => ({
    user: store.userReducer.user,
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(MeineValidierungenContainer);