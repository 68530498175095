import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StepTwoComponent from '../components/StepTwoComponent';
import { getOrganizationService } from 'services/settings';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";
import _ from 'lodash';

const StepTwo = props => {
    const { changeStep, data, submitStepData, readOnly } = props;

    const [isFetchingOrganizations, setIsFetchingOrganizations] = useState(false);
    const [organizationData, setOrganizationData] = useState([]);
    const [filesTreeData, setFilesTreeData] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        getOrganizationsData();
    }, []);

    const getOrganizationsData = async () => {
        setIsFetchingOrganizations(true);

        try {
            const response = await getOrganizationService();
            setOrganizationData(response);

            if (data.length) {
                const fetchedFilesTreeData = data.map(singleDepartment => {
                    let tenantId = '';
                    let groupId = '';
                    let departmentId = '';
                    let files = [];

                    response.organization.forEach(tenant => {
                        return tenant.groups.forEach(group => {
                            return group.departments.forEach(department => {
                                if (department.departmentId === singleDepartment.orgDepartmentId) {
                                    tenantId = tenant.tenantId;
                                    groupId = group.groupId;
                                    departmentId = department.departmentId;

                                    const departmentFiles = singleDepartment.directoryPath.map(path => {
                                        const pathNames = path.split("\\");
                                        const filename = _.last(pathNames);
                                        const smallPath = pathNames.length > 3 ? `${pathNames[0]}\\...\\${pathNames[pathNames.length - 2]}\\${pathNames[pathNames.length - 1]}` : path;

                                        return {
                                            name: filename,
                                            path,
                                            smallPath
                                        }
                                    });

                                    files = departmentFiles;
                                }
                            });
                        });
                    });

                    if (tenantId && groupId && departmentId) {
                        return {
                            tenantId,
                            groupId,
                            departmentId,
                            files,
                        }
                    }
                });

                setFilesTreeData(fetchedFilesTreeData);
            } else {
                const fileTreeData = {
                    tenantId: response.organization && response.organization.length ? response.organization[0].tenantId : '',
                    groupId: '',
                    departmentId: '',
                    files: [],
                }

                setFilesTreeData([fileTreeData]);
            }

        } catch (err) {
            console.log(err);
        }

        setIsFetchingOrganizations(false);
    }

    const setTreeTenantId = (treeIndex, id) => {
        const newTreeData = [{
            tenantId: parseInt(id),
            groupId: '',
            departmentId: '',
            files: []
        }];

        setFilesTreeData(newTreeData);
    }

    const setTreeGroupId = (treeIndex, id) => {
        let newTreeData = _.cloneDeep(filesTreeData);
        const selectedTreeData = newTreeData[treeIndex];
        const thisTenant = organizationData.organization.filter(organization => organization.tenantId === selectedTreeData.tenantId)[0];
        const thisTenantGroups = thisTenant ? thisTenant.groups : [];

        if (_.isNil(id) && thisTenantGroups.length) {
            newTreeData[treeIndex].groupId = parseInt(thisTenantGroups[0].groupId);
        } else {
            newTreeData[treeIndex].groupId = parseInt(id);
        };

        newTreeData[treeIndex].departmentId = '';

        if (!_.isNil(id) && id?.length === 0 && treeIndex > 0) {
            newTreeData.splice(treeIndex, 1);
        }

        setFilesTreeData(newTreeData);
    }

    const setTreeDepartmentId = (treeIndex, id) => {
        let newTreeData = _.cloneDeep(filesTreeData);
        const selectedTreeData = newTreeData[treeIndex];
        const thisTenant = organizationData.organization.filter(organization => organization.tenantId === selectedTreeData.tenantId)[0];
        const thisTenantGroup = thisTenant.groups.filter(group => group.groupId === selectedTreeData.groupId)[0];
        const thisTenantDepartments = thisTenantGroup ? thisTenantGroup.departments : [];

        if (_.isNil(id) && thisTenantDepartments.length) {
            newTreeData[treeIndex].departmentId = parseInt(thisTenantDepartments[0].departmentId);
        } else {
            newTreeData[treeIndex].departmentId = parseInt(id);
        };

        setFilesTreeData(newTreeData);
    }

    const setTreeNewFile = (treeIndex, fileData) => {
        const { name, path } = fileData;

        const pathNames = path.split("\\");
        const smallPath = pathNames.length > 3 ? `${pathNames[0]}\\...\\${pathNames[pathNames.length - 2]}\\${pathNames[pathNames.length - 1]}` : path;

        let newTreeData = _.cloneDeep(filesTreeData);

        newTreeData[treeIndex].files = [
            ...newTreeData[treeIndex].files,
            {
                name,
                path,
                smallPath
            }
        ];

        setFilesTreeData(newTreeData);
    }

    const deleteTreeFile = (treeIndex, fileIndex) => {
        let newTreeData = _.cloneDeep(filesTreeData);
        newTreeData[treeIndex].files.splice(fileIndex, 1);

        setFilesTreeData(newTreeData);
    }

    const addTreeItem = (isDepartment) => {
        const tenantId = filesTreeData[0].tenantId;

        const currentTenant = organizationData?.organization?.filter(tenant => tenant.tenantId === tenantId)[0];
        const currentTenantGroups = currentTenant?.groups;
        const firstGroupId = currentTenantGroups[0]?.groupId;

        const currentGroup = currentTenantGroups?.filter(group => group.groupId === firstGroupId)[0];
        const currentGroupDepartments = currentGroup?.departments;
        const firstDepartmentId = currentGroupDepartments[0]?.groupId;

        const fileTreeData = {
            tenantId: filesTreeData[0].tenantId,
            groupId: firstGroupId,
            departmentId: isDepartment ? firstDepartmentId : "",
            files: [],
        }

        setFilesTreeData([...filesTreeData, fileTreeData]);
    }

    const submitData = () => {
        let changeStepValid = true;
        let repeatedFilePaths = false;

        // Check if any field is missing
        filesTreeData.forEach(fileTreeData => {
            if (!fileTreeData.files.length || !fileTreeData.tenantId || !fileTreeData.groupId || !fileTreeData.departmentId) changeStepValid = false;
        });

        // Check if there are any duplicate or children paths 
        const directoriesArray = filesTreeData.map(rowData => {
            return rowData.files.map(file => `${file.path}\\`)
        }).flat(1);

        const duplicatedPaths = _.uniq(directoriesArray).length !== directoriesArray.length;
        const childrenPaths = directoriesArray.some((path, i) => {
            const arrayWithoutIndex = directoriesArray.filter((item, j) => i !== j);
            return arrayWithoutIndex.some(otherPath => otherPath.includes(path))
        })

        if (duplicatedPaths || childrenPaths) {
            repeatedFilePaths = true;
        }

        if (changeStepValid && !repeatedFilePaths) {
            submitStepData(filesTreeData, 2);
            changeStep(3);
        } else {
            let error = t('toastr_wizard_step2_error_message');
            if (repeatedFilePaths) error = t('toastr_wizard_step2_error_message_2');

            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={error} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
    }

    return (
        <StepTwoComponent
            isFetchingOrganizations={isFetchingOrganizations}
            submitData={submitData}
            organizationData={organizationData}
            filesTreeData={filesTreeData}
            setTreeTenantId={setTreeTenantId}
            setTreeGroupId={setTreeGroupId}
            setTreeDepartmentId={setTreeDepartmentId}
            setTreeNewFile={setTreeNewFile}
            deleteTreeFile={deleteTreeFile}
            addTreeItem={addTreeItem}
            readOnly={readOnly}
        />
    )
}

export default StepTwo;