import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InventoryQuestionsComponent from '../components/InventoryQuestionsComponent';
import { sendInventoryQuestionnaireService } from 'services/inventory';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const InventoryQuestions = (props) => {
    const [formSent, setFormSent] = useState(false);
    const [successStatus, setSuccessStatus] = useState('');
    const [filename, setFilename] = useState('');
    const [alias, setAlias] = useState('');
    const [isRiskOfficer, setIsRiskOfficer] = useState(false);

    const { t } = useTranslation();

    const sendQuestionsData = async (formData, isDraft, isRiskOfficer) => {
        try {
            const res = await sendInventoryQuestionnaireService(formData, isDraft);

            if (isDraft) {
                toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_inventory_saved')} />, {
                    progressClassName: "toastr-progress-bar success"
                });
            } else {
                setFilename(formData.fileName);
                setAlias(formData.alias);
                setSuccessStatus("Finish");
                setFormSent(true);
                setIsRiskOfficer(isRiskOfficer);
            }
            return res;
        } catch (err) {
            console.log(`Error: ${err}`);
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={err} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
    }

    return <InventoryQuestionsComponent
        {...props}
        successStatus={successStatus}
        filename={filename}
        alias={alias}
        sendQuestionsData={sendQuestionsData}
        formSent={formSent}
        isRiskOfficer={isRiskOfficer}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default withRouter(connect(mapStateToProps)(InventoryQuestions));