import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { withResizeDetector } from 'react-resize-detector';
import FileSelect from 'components/common/FileSelect/FileSelect';
import ColorPicker from 'components/common/ColorPicker/ColorPicker';
import Button from 'components/common/Button/Button';
import EucCheck from 'components/Guidance/EucCheck/containers/EucCheck';
import './DesignComponent.scss';

/*
    This component includes some component resize handling, because of transform: scale CSS property usage.
    It is used to make an existing component smaller.
*/

const ScreenThumbnail = ({ width, height, targetRef, mainColor, secondaryColor, logoFile, backgroundFile }) => {
    // The width of resizeDetector doesnt take paddings and borders into account. So we have to include it in our maths.
    const widthWithPaddingAndBorder = width + 20 + 2;
    const scale = (widthWithPaddingAndBorder / 1920 || 1) - 0.018;
    const thumbnailRef = useRef(null);
    const thumbnailHeight = thumbnailRef?.current?.getBoundingClientRect().height + 20 + 2;

    return (
        <div className="screen-thumbnail" style={{ height: thumbnailHeight || '100%' }} ref={targetRef}>
            <div className="screen-thumbnail-wrapper" style={{ transform: `scale(${scale})` }} ref={thumbnailRef}>
                {
                    width &&
                    <EucCheck previewTheme={{ mainColor, secondaryColor, logoFile, backgroundFile }} />
                }
            </div>
            <div className="overlay"></div>
        </div >
    )
}

const ScreenThumbnailWithDetector = withResizeDetector(ScreenThumbnail);

const DesignComponent = (props) => {
    const { updateInterfaceConfig, theme, resetInterface, setResetInterface, resetInterfaceConfig, isLoading } = props;
    const [backgroundFile, setBackgroundFile] = useState(null);
    const [backgroundFileName, setBackgroundFileName] = useState("");
    const [logoFile, setLogoFile] = useState(null);
    const [logoFileName, setLogoFileName] = useState("");
    const [mainColor, setMainColor] = useState(theme.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(theme.secondaryColor);
    const { t } = useTranslation();

    useEffect(() => {
        if (theme.mainColor) setMainColor(theme.mainColor);
    }, [theme.mainColor]);

    useEffect(() => {
        if (theme.secondaryColor) setSecondaryColor(theme.secondaryColor);
    }, [theme.secondaryColor])

    useEffect(() => {
        if (resetInterface) {
            setMainColor(theme.mainColor)
            setSecondaryColor(theme.secondaryColor);

            setLogoFile(theme.logoFile);
            setLogoFileName("");

            setBackgroundFile(theme.backgroundFile);
            setBackgroundFileName("");

            setResetInterface(false);
        }
    }, [resetInterface])

    return (
        <div className="design-wrapper">
            <div className="design-content">
                <div className="screen-configuration">
                    <div className="section-header">{t('settings_tab1_layout_chain_text_1')}</div>
                    <div className="section-content">
                        <div className="field-wrapper">
                            <div className="field-title">{t('settings_tab1_layout_chain_text_2')}</div>
                            <div className="field-input">
                                <FileSelect
                                    id="logo-file-input"
                                    inputText={logoFileName}
                                    buttonText={t('settings_tab1_button1_title')}
                                    allowedExtensions="image/*"
                                    onChange={fileObject => {
                                        setLogoFile(fileObject.base64);
                                        setLogoFileName(fileObject.name);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="field-wrapper">
                            <div className="field-title">{t('settings_tab1_layout_chain_text_3')}</div>
                            <div className="field-input">
                                <FileSelect
                                    id="background-file-input"
                                    inputText={backgroundFileName}
                                    buttonText={t('settings_tab1_button2_title')}
                                    allowedExtensions="image/*"
                                    onChange={fileObject => {
                                        setBackgroundFile(fileObject.base64);
                                        setBackgroundFileName(fileObject.name);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="field-wrapper">
                            <div className="field-title">{t('settings_tab1_layout_chain_text_4')}</div>
                            <div className="field-input">
                                <ColorPicker
                                    type="hex"
                                    colorHex={mainColor}
                                    onChange={color => setMainColor(color)} />
                            </div>
                        </div>
                        <div className="field-wrapper">
                            <div className="field-title">{t('settings_tab1_layout_chain_text_5')}</div>
                            <div className="field-input">
                                <ColorPicker
                                    type="hex"
                                    colorHex={secondaryColor}
                                    onChange={color => setSecondaryColor(color)} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="screen-preview">
                    <div className="section-header">{t('settings_tab1_layout_chain_text_6')}</div>
                    <div className="section-content">
                        <ScreenThumbnailWithDetector
                            logoFile={logoFile}
                            backgroundFile={backgroundFile}
                            mainColor={mainColor}
                            secondaryColor={secondaryColor} />
                        <div className="screen-preview-actions flex center">
                            <div>{t('settings_tab1_layout_chain_text_7')}:</div>
                            <Button onClick={() => resetInterfaceConfig()}>{t('settings_tab1_button3_title')}</Button>
                            <Button
                                loading={isLoading}
                                onClick={() => updateInterfaceConfig({ mainColor, secondaryColor, backgroundFile, logoFile })}>
                                {t('settings_tab1_button4_title')}
                            </Button>
                        </div>

                        <div className="app-version">
                            <span>EUC Portal v3.2.3</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DesignComponent;