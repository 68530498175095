import React, { useState, useCallback } from "react";
import Layout from "layouts/layout";
import { useTranslation } from "react-i18next";
import { BiExport } from "react-icons/bi";
import { ImSearch } from "react-icons/im";
import FileOverviewModal from "components/modals/FileOverviewModal/containers/FileOverviewModal";
import InventoryUserPicker from "components/modals/InventoryUserPicker/containers/InventoryUserPicker";
import TableSkeletonLoading from "components/common/TableSkeletonLoading/TableSkeletonLoading";
import TableSortIcon from "components/common/TableSortIcon/TableSortIcon";
import TableActionButton from "components/common/TableActionButton/TableActionButton";
import { GrUndo } from "react-icons/gr";
import { IoIosArrowForward } from "react-icons/io";
import { BiLockAlt } from "react-icons/bi";
import moment from "moment";
import { formatThousandsAndDecimals, parseFileOverviewModalMetadata } from "services/helper";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import TablePagination from "components/common/TablePagination/TablePagination";
import DownloadCsvModal from "components/modals/DownloadCsvModal/containers/DownloadCsvModal";
import Input from "components/common/Input/Input";
import "./InventoryComponent.scss";

const InventoryComponent = (props) => {
    const {
        data,
        loading,
        reloadTab,
        localFilters,
        setLocalFilters,
        changedTableLoading,
        pagination,
        changePageData,
        dataCount,
        changeInventoryStatus
    } = props;
    const { t } = useTranslation();

    const [isDownloadCsvModalOpen, setIsDownloadCsvModalOpen] = useState(false);
    const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);
    const [isUserPickerModalOpen, setIsUserPickerModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const debouncedText = useCallback(_.debounce((newLocalFilters) => reloadTab(newLocalFilters), 1000), []);

    const changeFilter = (key, value, debounceIt) => {
        let newLocalFilters = _.cloneDeep(localFilters);

        if (Array.isArray(key)) {
            key.forEach((thisKey, i) => {
                _.set(newLocalFilters, thisKey, value[i]);
            });
        } else {
            _.set(newLocalFilters, key, value);
        }

        if (debounceIt) {
            debouncedText(newLocalFilters)
        } else {
            reloadTab(newLocalFilters);
        }

        setLocalFilters(newLocalFilters);
    }

    const sortTable = (column) => {
        let sortOrder = "asc";

        if (currentSortColumn === column) {
            if (currentSortOrder === "asc") sortOrder = "desc";
            if (currentSortOrder === "desc") {
                sortOrder = null;
                column = null;
            };
        }

        const filterReversed = sortOrder === "asc" ? false : (sortOrder === "desc" ? true : null);
        changeFilter(["order", "reverse"], [column, filterReversed]);

        setCurrentSortColumn(column);
        setCurrentSortOrder(sortOrder);
    }

    const openFileOverviewModal = (row) => {
        const item = parseFileOverviewModalMetadata(row);
        setSelectedItem(item);
        setIsOverviewModalOpen(true);
    }

    const openInventoryUserPickerModal = row => {
        const item = parseFileOverviewModalMetadata(row);
        setSelectedItem(item);
        setIsUserPickerModalOpen(true);
    }

    const headerColumns = [
        {
            columnName: t("screenInventory_table_header_1"),
            key: "fileId",
            orderKey: "id",
            style: {
                minWidth: 40,
                maxWidth: 50
            }
        },
        {
            columnName: t("screenInventory_table_header_2"),
            key: "version",
            orderKey: "version",
            style: {
                minWidth: 70,
                maxWidth: 70
            }
        },
        {
            columnName: t("screenInventory_table_header_3"),
            key: null,
            orderKey: "inventoryStatus",
            style: {
                minWidth: 90,
            }
        },
        {
            columnName: t("screenInventory_table_header_5"),
            key: null,
            orderKey: "protectionRequirement",
            style: {
                minWidth: 110,
            }
        },
        {
            columnName: t("screenInventory_table_header_6"),
            key: "fileName",
            style: {
                minWidth: 200,
            }
        },
        {
            columnName: t("screenInventory_table_header_8"),
            key: "technologies",
            multiSelectOptions: [
                {
                    label: t("inventory_management_filter_technology_excel"),
                    value: "excel"
                },
                {
                    label: t("inventory_management_filter_technology_access"),
                    value: "access"
                },
                {
                    label: t("inventory_management_filter_technology_other"),
                    value: "other"
                },
            ],
            style: {
                minWidth: 85,
            }
        },
        {
            columnName: t("screenInventory_table_header_10"),
            key: "bussinesOfficer",
        },
        {
            columnName: t("screenInventory_table_header_11"),
            key: "riskOfficer",
        },
        {
            columnName: t("screenInventory_table_header_12"),
            key: null,
            orderKey: "inventory",
            style: {
                minWidth: 100,
            }
        },
        {
            columnName: t("screenInventory_table_header_13"),
            key: null,
            orderKey: "lastInventoryDate",
            style: {
                minWidth: 100,
            }
        },
        {
            columnName: t("screenInventory_table_header_14"),
            key: null
        },
    ]

    const dataFileIds = data.map(file => file.fileId);

    return (
        <Layout className="inventory-page" contentCardStyles={{ height: "100%" }}>
            <div className="inventory-page-wrapper">
                <div className="inventory-page-content">
                    <div className="inventory-header">
                        <h2>{t("screenInventory_section_title")}</h2>

                        <div className="inventory-details">
                            <div className="inventory-actions">
                                <div className="action" onClick={() => setIsDownloadCsvModalOpen(true)}>
                                    <BiExport style={{ transform: "rotate(90deg)" }} />
                                    <span>{t("screenInventory_section_action_export")}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        loading ?
                            <TableSkeletonLoading rows={5} columns={11} style={{ marginTop: 20 }} /> :

                            <div>
                                <table className="kpmg-table secondary inventory-table">
                                    <thead>
                                        <tr>
                                            {
                                                headerColumns.map((headerColumn, i) => (
                                                    <th key={i} style={{ minWidth: 120, ...headerColumn.style }}>
                                                        <div className="table-head column">
                                                            <div className="table-head-content" onClick={() => headerColumn.orderKey && sortTable(headerColumn.orderKey)}>
                                                                <span className="table-head-column-name">{headerColumn.columnName}</span>
                                                                {
                                                                    headerColumn.orderKey &&
                                                                    <div className="sort-icon">
                                                                        <TableSortIcon
                                                                            columnName={headerColumn.orderKey}
                                                                            sortColumn={currentSortColumn}
                                                                            sortOrder={currentSortOrder}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                            <hr style={{ width: "100%" }} />

                                                            <div className="table-head-input">
                                                                {
                                                                    headerColumn.key !== null ?
                                                                        (
                                                                            headerColumn?.multiSelectOptions?.length ?
                                                                                <Input
                                                                                    type="multi-select"
                                                                                    inputOptions={{
                                                                                        className: "custom-select",
                                                                                        options: headerColumn.multiSelectOptions,
                                                                                        value: localFilters[headerColumn.key] || [],
                                                                                        onChange: values => changeFilter(headerColumn.key, values, true)
                                                                                    }}
                                                                                /> :
                                                                                <input
                                                                                    className="input-text"
                                                                                    type="text"
                                                                                    value={localFilters[headerColumn.key]}
                                                                                    onChange={e => changeFilter(headerColumn.key, e.target.value, true)}
                                                                                    disabled={!headerColumn.key}
                                                                                />
                                                                        ) :
                                                                        null

                                                                }
                                                            </div>
                                                        </div>
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            changedTableLoading ?
                                                _.times(5, (i) => (
                                                    <tr key={i}>
                                                        {
                                                            _.times(11, (j) => (
                                                                <td key={j} style={{ backgroundColor: "white" }}>
                                                                    <Skeleton width={60} height={10} />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                )) :
                                                data.length ?
                                                    data.map((item, i) => {
                                                        const technologies = item?.technologies?.replace(/\\"/g, '').split(",").join(", ");

                                                        return (
                                                            <tr key={i}>
                                                                <td className="center-row">{formatThousandsAndDecimals(item.fileId)}</td>
                                                                <td>{item.inventorySectionVersion}</td>
                                                                <td>{item.status}</td>
                                                                <td>
                                                                    <div className="danger-wrapper">
                                                                        {
                                                                            !_.isNil(item.protectionRequirement) ?
                                                                                _.times(item.protectionRequirement, i => (
                                                                                    <div className={`danger level${i + 1}`} key={i}></div>
                                                                                )) :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>{item.fileName}</td>
                                                                <td>{technologies || "-"}</td>
                                                                <td>{item.businessOfficer}</td>
                                                                <td>{item.riskOfficer}</td>
                                                                <td>{item.firstInventoryDate ? moment(item.firstInventoryDate).format("DD/MM/YYYY") : "-"}</td>
                                                                <td>{item.lastInventoryDate ? moment(item.lastInventoryDate).format("DD/MM/YYYY") : "-"}</td>
                                                                <td>
                                                                    <div className="action-buttons">
                                                                        <TableActionButton style={{ fontSize: 14 }}
                                                                            onClick={() => openFileOverviewModal(item)}
                                                                            toolTip={t("screenInventory_tooltip_questionnaire_history")} >
                                                                            <ImSearch />
                                                                        </TableActionButton>

                                                                        <TableActionButton
                                                                            linkTo={`/management/manage-inventory?inventoryId=${item.inventoryId}`}
                                                                            toolTip={t("screenInventory_tooltip_update_questionnaire")}
                                                                            disabled={item.status === "Finalized"}>
                                                                            <IoIosArrowForward />
                                                                        </TableActionButton>

                                                                        <TableActionButton
                                                                            onClick={() => openInventoryUserPickerModal(item)}
                                                                            toolTip={t("screenInventory_tooltip_resend_questionnaire")}
                                                                            disabled={item.status === "Finalized"}>
                                                                            <GrUndo />
                                                                        </TableActionButton>

                                                                        {
                                                                            item.status !== "Finalized" &&
                                                                            <TableActionButton
                                                                                onClick={() => changeInventoryStatus(item.inventoryId)}
                                                                                toolTip={t("screenInventory_tooltip_archive_questionnaire")}>
                                                                                <BiLockAlt />
                                                                            </TableActionButton>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :
                                                    <tr>
                                                        <td colSpan={14}>{t("analysis_tab1_listenansicht_empty")}</td>
                                                    </tr>
                                        }
                                    </tbody>
                                </table>

                                <TablePagination
                                    pagination={pagination}
                                    dataCount={dataCount}
                                    changePageDataCallback={newPagination => changePageData(newPagination, null)}
                                />
                            </div>
                    }
                </div>
            </div>

            <FileOverviewModal
                isOpen={isOverviewModalOpen}
                setIsOpen={setIsOverviewModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                hideSnapshotsPagination={true}
            />

            {
                isUserPickerModalOpen &&
                <InventoryUserPicker
                    isOpen={isUserPickerModalOpen}
                    setIsOpen={setIsUserPickerModalOpen}
                    selectedItems={[selectedItem]}
                    setSelectedItem={setSelectedItem}
                    reloadTab={reloadTab}
                />
            }

            <DownloadCsvModal
                isOpen={isDownloadCsvModalOpen}
                setIsOpen={setIsDownloadCsvModalOpen}
                localFilters={localFilters}
                fileIds={dataFileIds}
            />
        </Layout>
    )
};

export default InventoryComponent;