import React, { useEffect, useState } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import { FaCheck } from 'react-icons/fa';
import TableSortIcon from 'components/common/TableSortIcon/TableSortIcon';
import _ from 'lodash';
import Skeleton from "react-loading-skeleton";
import './BerechtigungenComponent.scss';

const BerechtigungenComponentLoading = () => {
    return (
        <div>
            <div style={{ marginBottom: 20, width: '100%' }}>
                <Skeleton width={250} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
                <Skeleton width={150} />
            </div>

            {
                _.times(5, (j) => (
                    <div key={j} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                        <Skeleton width={150} />
                    </div>
                ))
            }
        </div>
    )
}

const BerechtigungenComponent = (props) => {
    const { users, isLoading, allowedModalPermission, setConfigValidation } = props;
    const { t } = useTranslation();
    const [currentUsers, setCurrentUsers] = useState([]);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    useEffect(() => {
        if (users.length) {
            const newCurrentUsers = users.map(user => {
                return {
                    name: user.displayName ? user.displayName : user.samAccountName,
                    eucScan: user.groupPermissions.includes("EUCSCAN"),
                    eucDashboardRead: user.groupPermissions.includes("EUCDASHBOARDREAD"),
                    eucDashboardWrite: user.groupPermissions.includes("EUCDASHBOARDWRITE"),
                    eucInventory: user.groupPermissions.includes("EUCINVENTORY"),
                    eucInventoryManagement: user.groupPermissions.includes("EUCINVENTORYMANAGEMENT"),
                    eucAdmin: user.groupPermissions.includes("EUCADMIN"),
                }
            });

            setCurrentUsers(newCurrentUsers);
        };
    }, [users]);

    const sortTable = (column) => {
        let sortOrder = 'asc';

        if (currentSortColumn === column) {
            sortOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
        }

        const sortedUsers = _.orderBy(currentUsers, [column], [sortOrder]);

        setCurrentUsers(sortedUsers);
        setCurrentSortColumn(column);
        setCurrentSortOrder(sortOrder);
    }

    return (
        <Layout>
            <div className="berechtigungen-wrapper">
                {
                    isLoading ?
                        <BerechtigungenComponentLoading /> :
                        <div className="berechtigungen-content overflow-x">
                            <table className="kpmg-table stripped">
                                <thead>
                                    <tr>
                                        <th colSpan={7}>{t('settings_tab2_layout_chain_text_1')}</th>
                                    </tr>
                                    <tr>
                                        <th onClick={() => sortTable('name')}>
                                            <div className="table-head">
                                                <span>{t('settings_tab2_layout_chain_column_1')}</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="name"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucScan')}>
                                            <div className="table-head">
                                                <span>EUCSCAN</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucScan"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucDashboardRead')}>
                                            <div className="table-head">
                                                <span>EUCDASHBOARDREAD</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucDashboardRead"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucDashboardWrite')}>
                                            <div className="table-head">
                                                <span>EUCDASHBOARDWRITE</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucDashboardWrite"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucInventory')}>
                                            <div className="table-head">
                                                <span>EUCINVENTORY</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucInventory"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucInventoryManagement')}>
                                            <div className="table-head">
                                                <span>EUCINVENTORYMANAGEMENT</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucInventoryManagement"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => sortTable('eucAdmin')}>
                                            <div className="table-head">
                                                <span>EUCADMIN</span>
                                                <div className="sort-icon">
                                                    <TableSortIcon
                                                        columnName="eucAdmin"
                                                        sortColumn={currentSortColumn}
                                                        sortOrder={currentSortOrder} />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className="empty">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {
                                        currentUsers.map((user, i) => (
                                            <tr key={i}>
                                                <td style={{ textAlign: 'left' }}>{user.name}</td>
                                                <td>
                                                    {
                                                        user.eucScan ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        user.eucDashboardRead ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        user.eucDashboardWrite ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        user.eucInventory ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        user.eucInventoryManagement ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        user.eucAdmin ?
                                                            <div className="permission">
                                                                <FaCheck />
                                                            </div> :
                                                            "-"
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>


                            <div className="allow-modal-permissions">
                                <input
                                    type="checkbox"
                                    id="allowModalPermissions"
                                    name="allowModalPermissions"
                                    checked={allowedModalPermission}
                                    onChange={() => setConfigValidation(!allowedModalPermission)}
                                />
                                <label htmlFor="allowModalPermissions">{t('settings_tab2_layout_chain_column_6')}</label>
                            </div>
                        </div>
                }
            </div>
        </Layout>
    )
};

export default BerechtigungenComponent;