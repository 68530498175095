import React, { useState } from "react";
import Button from 'components/common/Button/Button';
import { useTranslation } from "react-i18next";
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { FcFolder, FcOpenedFolder, FcFile } from 'react-icons/fc';
import { FiPlus, FiMinus } from 'react-icons/fi';
import Tree from 'rc-tree';

import './FileTreeScanModalComponent.scss';
import 'rc-tree/assets/index.css';

const FileTreeScanModalComponent = (props) => {
    const { isOpen, setIsOpen, selectFileCallback, settings, treeData, isFetching } = props;
    const [selectedFile, setSelectedFile] = useState([]);
    const mainColor = settings?.theme?.mainColor;
    const { t } = useTranslation();

    const getIcon = props => {
        // if (props.data.children?.length) {
        return props.expanded ? <FcOpenedFolder style={{ fontSize: 18 }} /> : <FcFolder style={{ fontSize: 18 }} />;
        // }

        // return <FcFile style={{ fontSize: 18 }} />
    }

    const getSwitchIcon = props => {
        if (!props.data.children?.length) {
            return null
        }

        return props.expanded ? <FiMinus style={{ opacity: 0.7, fontSize: 13 }} /> : <FiPlus style={{ opacity: 0.7, fontSize: 13 }} />
    }

    const selectFile = (props, info) => {
        const path = findPath(props[0], treeData)

        setSelectedFile({
            directoryId: info.node.key,
            name: info.node.title,
            path
        })
    }

    const findPath = (value, data) => {
        const selectedNodes = [];

        function loop(selected, children) {
            for (let i = 0; i < children.length; i++) {
                const item = children[i];
                if (selected === item.key) {
                    selectedNodes.push(item);
                    return;
                }

                if (item.children) {
                    loop(selected, item.children, item);
                    if (selectedNodes.length) {
                        selectedNodes.push(item);
                        return;
                    }
                }
            }
        }

        loop(value, data);

        const filePath = selectedNodes.slice().reverse().map(treeLeaf => treeLeaf.title).join("\\");
        return filePath;
    }

    return (
        <Modal
            isOpen={isOpen}
            className="kpmg-modal file-tree-scan-modal"
            overlayClassName="kpmg-modal-overlay"
            onRequestClose={() => setIsOpen(false)}
            ariaHideApp={false}>
            <div className="modal-header">
                <h3>{t('filetree_modal_title')}</h3>
                <p style={{ color: mainColor }}>{t('filetree_modal_subtitle')}</p>

                <div className="close-icon" onClick={() => setIsOpen(false)}>
                    <FaTimes />
                </div>
            </div>

            <div className="modal-body">
                <div className="tree-wrapper">
                    <Tree
                        className="myCls"
                        // defaultExpandAll
                        treeData={treeData}
                        onSelect={selectFile}
                        icon={(props) => getIcon(props)}
                        switcherIcon={(props) => getSwitchIcon(props)}
                        showLine={true}
                        height={415}
                    />
                </div>
                <div className="action-buttons">
                    <Button onClick={() => {
                        selectFileCallback(selectedFile);
                        setIsOpen(false);
                    }}>{t('filetree_modal_button')}</Button>
                </div>
            </div>
        </Modal>
    )
};

export default FileTreeScanModalComponent;