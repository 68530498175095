import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import _ from 'lodash';
import Button from 'components/common/Button/Button';
import { BiEditAlt } from 'react-icons/bi';
import UserListModal from 'components/modals/UserListModal/containers/UserListModal';
import './EmailSettings.scss';

const RiskOfficers = (props) => {
    const { t } = useTranslation();

    const { riskOfficerList, loading, updateRiskOfficer } = props;

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [unsavedData, setUnsavedData] = useState([]);
    const [usersModalOpen, setUsersModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        setUnsavedData(riskOfficerList);
    }, [riskOfficerList])

    const discardChanges = () => {
        setUnsavedData(riskOfficerList);
    }

    const editCurrentRiskOfficer = (user) => {
        let listCopy = _.cloneDeep(unsavedData);
        listCopy[selectedIndex] = {
            ...listCopy[selectedIndex],
            name: user.samAccountName
        };
        setUnsavedData(listCopy);
        setUsersModalOpen(false);
    }

    return (
        <div className="email-settings-content">
            <div className="form-wrapper">
                <table className="kpmg-table secondary">
                    <thead>
                        <tr>
                            <th>Risk Officer</th>
                            <th style={{ width: 60 }}></th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            unsavedData?.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.name || "-"}</td>

                                    <td>
                                        <div className="action-buttons">
                                            <div className="table-btn arrow" onClick={() => {
                                                setSelectedIndex(i);
                                                setSearchTerm("group=EUCINVENTORY");
                                                setUsersModalOpen(true);
                                            }}>
                                                <BiEditAlt />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

                <div className="actions">
                    <div>{t('settings_tab1_layout_chain_text_7')}:</div>
                    <Button onClick={() => discardChanges()}>{t('settings_tab1_button3_title')}</Button>
                    <Button
                        loading={loading}
                        onClick={() => updateRiskOfficer(unsavedData[0])}>
                        {t('settings_tab1_button4_title')}
                    </Button>
                </div>
            </div>

            <UserListModal
                isOpen={usersModalOpen}
                setIsOpen={setUsersModalOpen}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                selectUserCallback={editCurrentRiskOfficer}
            />
        </div>
    )
}

export default RiskOfficers;