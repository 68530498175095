import React, { useState } from "react";
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import Indicators from './Indicators/containers/Indicators';
import Keywords from './Keywords/containers/Keywords';
import Metadata from './Metadata/containers/Metadata';
import _ from 'lodash';
import './KalibrierungScanComponent.scss';
import ScanSettings from "./ScanSettings/containers/ScanSettings";

const KalibrierungScanComponent = (props) => {
    const {
        settings,
    } = props;

    const [selectedTab, setSelectedTab] = useState('indicators');

    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    return (
        <Layout>
            <div className="kalibrierung-scan-wrapper">
                <div className="kalibrierung-scan-content">
                    <table className="kpmg-table kpmg-table-selectors stripped">
                        <thead>
                            <tr className="row-header">
                                <th style={{ maxWidth: 120, width: 120 }}>
                                    <div
                                        style={{ borderColor: selectedTab === 'indicators' ? mainColor : "#525662" }}
                                        onClick={() => setSelectedTab('indicators')}
                                        className={`section-header-item ${selectedTab === 'indicators' ? "active" : null}`}>
                                        {t('settings_tab4_layout_chain_text_1_a')}
                                    </div>
                                </th>
                                <th style={{ maxWidth: 120, width: 120 }}>
                                    <div
                                        style={{ borderColor: selectedTab === 'keywords' ? mainColor : "#525662" }}
                                        onClick={() => setSelectedTab('keywords')}
                                        className={`section-header-item ${selectedTab === 'keywords' ? "active" : null}`}>
                                        {t('settings_tab4_layout_chain_text_2_a')}
                                    </div>
                                </th>
                                <th style={{ maxWidth: 120, width: 120 }}>
                                    <div
                                        style={{ borderColor: selectedTab === 'metadata' ? mainColor : "#525662" }}
                                        onClick={() => setSelectedTab('metadata')}
                                        className={`section-header-item ${selectedTab === 'metadata' ? "active" : null}`}>
                                        {t('settings_tab4_layout_chain_text_metadata')}
                                    </div>
                                </th>
                                <th style={{ maxWidth: 120, width: 120 }}>
                                    <div
                                        style={{ borderColor: selectedTab === 'settings' ? mainColor : "#525662" }}
                                        onClick={() => setSelectedTab('settings')}
                                        className={`section-header-item ${selectedTab === 'settings' ? "active" : null}`}>
                                        {t('settings_tab4_layout_chain_text_settings')}
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>

                    {
                        selectedTab === 'indicators' &&
                        <Indicators />
                    }

                    {
                        selectedTab === 'keywords' &&
                        <Keywords />
                    }

                    {
                        selectedTab === 'metadata' &&
                        <Metadata
                        />
                    }

                    {
                        selectedTab === 'settings' &&
                        <ScanSettings />
                    }

                </div>
            </div>
        </Layout>
    )
};

export default KalibrierungScanComponent;