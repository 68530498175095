import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import InventarisierungenComponent from '../components/InventarisierungenComponent';
import { changeInventoryStatusService, getInventoryDataService, deleteInventoryService } from 'services/inventory';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions';
import _ from 'lodash';
import { sendEmail } from 'services/helper';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const Inventarisierungen = (props) => {
    const { settings } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const [inventoryFormOpen, setInventoryFormOpen] = useState({
        data: [],
        count: 0
    });

    const [inventoryFormPending, setInventoryFormPending] = useState({
        data: [],
        count: 0
    });

    const [inventoryFormClosed, setInventoryFormClosed] = useState({
        data: [],
        count: 0
    });

    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
        limit: 100,
        selectValue: 100,
        currentIndex: 1,
    });

    const { t } = useTranslation();

    const isAllowedValidation = settings?.validationConfig?.inventoryAutoClose === 1;

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        getInventoryDataByStatus(['Open', 'Requested'], pagination);
        if (!isAllowedValidation) getInventoryDataByStatus('PendingAcceptance', pagination);
        getInventoryDataByStatus('Closed', pagination);
    }, []);

    const getInventoryDataByStatus = async (status, pagination) => {
        setLoading(true);

        try {
            const responseLength = await getInventoryDataService({ inventorystatus: status }, false, pagination, true);
            const response = await getInventoryDataService({ inventorystatus: status }, false, pagination);
            const responseSorted = _.orderBy(response, (item) => +new Date(item.createdAt), ['desc']);

            if (_.isEqual(['Open', 'Requested'], status)) {
                setInventoryFormOpen({
                    data: responseSorted,
                    count: responseLength.count
                });
            }

            if (_.isEqual('PendingAcceptance', status)) {
                setInventoryFormPending({
                    data: responseSorted,
                    count: responseLength.count
                });
            }

            if (_.isEqual('Closed', status)) {
                setInventoryFormClosed({
                    data: responseSorted,
                    count: responseLength.count
                });
            }
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setLoading(false);
    }

    const changeInventoryStatus = async (inventoryId, status) => {
        const formData = { inventoryId, status }

        try {
            if (await confirm({
                title: t('validation_change_status_confirm_title'),
                confirmation: t('validation_change_status_confirm_message')
            })) {
                await changeInventoryStatusService(formData);

                getInventoryDataByStatus(['Open', 'Requested']);
                if (!isAllowedValidation) getInventoryDataByStatus('PendingAcceptance');
                getInventoryDataByStatus('Closed');
            }
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const deleteInventory = async (inventoryId) => {
        try {
            if (await confirm({
                title: t('delete_inventory_confirm_title'),
                confirmation: t('delete_inventory_confirm_message')
            })) {
                await deleteInventoryService(inventoryId);

                getInventoryDataByStatus(['Open', 'Requested']);
                getInventoryDataByStatus('PendingAcceptance');
                getInventoryDataByStatus('Closed');
            }
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const reloadTabs = (newPagination) => {
        getInventoryDataByStatus(['Open', 'Requested'], newPagination);
        if (!isAllowedValidation) getInventoryDataByStatus('PendingAcceptance', newPagination);
        getInventoryDataByStatus('Closed', newPagination);
    }

    const changePageData = (newPagination) => {
        setPagination(newPagination);
        reloadTabs(newPagination);
    }

    const sendEmailAction = async (emailType, samAccount) => {
        try {
            await sendEmail(emailType, samAccount);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_mail_sent_success')} />, {
                progressClassName: "toastr-progress-bar success"
            });
        } catch (err) {
            toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_mail_sent_error')} />, {
                progressClassName: "toastr-progress-bar danger"
            });
        }
    }

    return <InventarisierungenComponent
        {...props}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        loading={loading}
        inventoryFormOpen={inventoryFormOpen}
        inventoryFormPending={inventoryFormPending}
        inventoryFormClosed={inventoryFormClosed}
        changeInventoryStatus={changeInventoryStatus}
        deleteInventory={deleteInventory}
        pagination={pagination}
        changePageData={changePageData}
        sendEmailAction={sendEmailAction}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(Inventarisierungen);