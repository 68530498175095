import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from 'components/common/Button/Button';
import ModalLayout from 'layouts/modal-layout/modal-layout';
import './DownloadCsvModalComponent.scss';

const DownloadCsvModalComponent = (props) => {
    const {
        isOpen,
        setIsOpen,
        isDownloading,
        downloadCsv,
        license,
        hideFirstQuestion = false
    } = props;

    const [filesToExport, setFilesToExport] = useState("all-files");
    const [columnsToExport, setColumnsToExport] = useState([]);

    useEffect(() => {
        if (!isOpen) {
            setFilesToExport("all-files");
            setColumnsToExport([]);
        }
    }, [isOpen])

    const { t } = useTranslation();

    const changeColumnToExport = column => {
        let newColumnsToExport = [...columnsToExport];

        if (columnsToExport.includes(column)) {
            newColumnsToExport = newColumnsToExport.filter(thisColumn => thisColumn !== column);
        } else {
            newColumnsToExport.push(column);
        }

        setColumnsToExport(newColumnsToExport);
    }

    const licenseHasManagement = license?.resources?.includes("Management");
    const licenseHasValidation = license?.resources?.includes("Validation");

    return (
        <ModalLayout
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalCustomClass="download-csv-modal"
            modalTitle={t('multiple_download_csv_modal_title')}
            modalSubtitle={t('multiple_download_csv_modal_description')}
            modalFooter={
                <div>
                    <Button
                        style={{ width: 170, marginRight: 20 }}
                        loading={isDownloading}
                        onClick={() => downloadCsv("excel", filesToExport, columnsToExport)}>
                        {t('multiple_download_csv_modal_export_excel')}
                    </Button>

                    <Button
                        style={{ width: 170 }}
                        loading={isDownloading}
                        onClick={() => downloadCsv("csv", filesToExport, columnsToExport)}>
                        {t('multiple_download_csv_modal_export_csv')}
                    </Button>
                </div>
            }
        >
            <div>
                {
                    !hideFirstQuestion &&
                    <div className="section">
                        <div className="section-title">{t('multiple_download_csv_modal_section_1_title')}</div>

                        <div className="input-wrapper">
                            <input
                                type="radio"
                                name="files-export"
                                id="files-export-1"
                                checked={filesToExport === "all-files"}
                                onChange={(e) => setFilesToExport("all-files")}
                            />
                            <label htmlFor="files-export-1">{t('multiple_download_csv_modal_section_1_option_all')}</label>
                        </div>

                        {
                            licenseHasValidation &&
                            <div className="input-wrapper">
                                <input
                                    type="radio"
                                    name="files-export"
                                    id="files-export-2"
                                    checked={filesToExport === "validation"}
                                    onChange={(e) => setFilesToExport("validation")}
                                />
                                <label htmlFor="files-export-2">{t('multiple_download_csv_modal_section_1_option_validation')}</label>
                            </div>
                        }

                        {
                            licenseHasManagement &&
                            <div className="input-wrapper">
                                <input
                                    type="radio"
                                    name="files-export"
                                    id="files-export-3"
                                    checked={filesToExport === "inventory"}
                                    onChange={(e) => setFilesToExport("inventory")}
                                />
                                <label htmlFor="files-export-3">{t('multiple_download_csv_modal_section_1_option_inventory')}</label>
                            </div>
                        }

                        <div className="section-description">{t('multiple_download_csv_modal_section_1_description')}</div>
                    </div>
                }

                <div className="section">
                    <div className="section-title">
                        {t('multiple_download_csv_modal_section_2_title')}
                    </div>

                    <div className="input-wrapper">
                        <input
                            type="checkbox"
                            id="metadata"
                            checked={columnsToExport.includes("metadata")}
                            onChange={e => changeColumnToExport("metadata")}
                        />
                        <label htmlFor="metadata">{t('multiple_download_csv_modal_section_2_metadata')}</label>
                    </div>

                    {
                        licenseHasManagement &&
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                id="inventory"
                                checked={columnsToExport.includes("inventory")}
                                onChange={e => changeColumnToExport("inventory")}
                            />
                            <label htmlFor="inventory">{t('multiple_download_csv_modal_section_2_inventory')}</label>
                        </div>
                    }

                    {
                        licenseHasValidation &&
                        <div className="input-wrapper">
                            <input
                                type="checkbox"
                                id="last-answers"
                                checked={columnsToExport.includes("last-answers")}
                                onChange={e => changeColumnToExport("last-answers")}
                            />
                            <label htmlFor="last-answers">{t('multiple_download_csv_modal_section_2_answers')}</label>
                        </div>
                    }

                    <div className="input-wrapper">
                        <input
                            type="checkbox"
                            id="last-scans"
                            checked={columnsToExport.includes("last-scans")}
                            onChange={e => changeColumnToExport("last-scans")}
                        />
                        <label htmlFor="last-scans">{t('multiple_download_csv_modal_section_2_scan')}</label>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
};

export default DownloadCsvModalComponent;