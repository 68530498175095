import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InventoryComponent from '../components/InventoryComponent';
import { getInventoryManagementService, changeInventoryStatusService } from 'services/inventory';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'
import { useTranslation } from "react-i18next";

const Inventory = (props) => {
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [changedTableLoading, setChangedTableLoading] = useState(false);

    const [localFilters, setLocalFilters] = useState({
        order: null,
        reverse: null
    });

    const [pagination, setPagination] = useState({
        limit: 100,
        selectValue: 100,
        currentIndex: 1,
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (window.document.documentMode) {
            alert("This page will not work properly when using Internet Explorer. Please use another browser, such as Microsoft Edge.")
        }

        setLoading(true);
        getInventoryManagementData(localFilters, pagination);
    }, []);

    const getInventoryManagementData = async (localFilters, newPagination) => {
        setChangedTableLoading(true);

        try {
            const response = await getInventoryManagementService(localFilters, newPagination);
            const responseParsed = response.map(inventory => {
                return {
                    ...inventory,
                    protectionRequirement: inventory?.protectionRequirement?.replace(/\D+/g, '')
                }
            })
            const responseCount = await getInventoryManagementService(localFilters, newPagination, true);
            setData(responseParsed);
            setDataCount(responseCount.count);
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }

        setChangedTableLoading(false);
        setLoading(false);
    }

    const reloadTab = (localFilters) => {
        getInventoryManagementData(localFilters);
    }

    const changePageData = (newPagination) => {
        setChangedTableLoading(true);
        setPagination(newPagination);
        getInventoryManagementData(localFilters, newPagination);
    }

    const changeInventoryStatus = async (inventoryId) => {
        if (await confirm({
            title: t('reject_inventory_confirm_title'),
            confirmation: t('reject_inventory_confirm_message'),
        })) {
            try {
                const formData = { inventoryId, status: "Finalized" }
                await changeInventoryStatusService(formData);
                await reloadTab(localFilters);
            } catch (err) {
                console.log(`Error while rejecting inventory form: ${err}`);
            }
        }
    }

    return <InventoryComponent
        {...props}
        data={data}
        loading={loading}
        reloadTab={reloadTab}
        localFilters={localFilters}
        setLocalFilters={setLocalFilters}
        changedTableLoading={changedTableLoading}
        pagination={pagination}
        dataCount={dataCount}
        changePageData={changePageData}
        changeInventoryStatus={changeInventoryStatus}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(Inventory);