import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InfoTextModal from 'components/modals/InfoTextModal/InfoTextModal';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import { BsPencilSquare } from 'react-icons/bs';
import Button from 'components/common/Button/Button';
import _ from 'lodash';

const IndicatorsComponent = (props) => {
    const {
        isFetchingIndicators,
        allIndicators,
        editIndicator,
        submitIndicators,
        isSubmitting,
        discardIndicatorsChanges
    } = props;

    const [infoTextModalOpen, setInfoTextModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { t, i18n } = useTranslation();
    const translationsLoaded = i18n.hasResourceBundle('en', 'translations');

    const openInfoTextModal = (item) => {
        setSelectedItem(item);
        setInfoTextModalOpen(true);
    }

    const submitInfoCallback = ({ infoTextDe, infoTextEn }) => {
        const infoText = {
            en: infoTextEn,
            de: infoTextDe,
        };

        const indicatorId = selectedItem.indicatorId;
        editIndicator(indicatorId, 'infoText', infoText);
    }

    const mapKeyToTranslation = key => {
        if (key === 'MetaData') return t('scan_tab1_wizard_step5_tip_chain_text_1')
        if (key === 'Technichal') return t('scan_tab1_wizard_step4_tip_chain_text_2');
        if (key === 'Links') return t('scan_tab1_wizard_step4_tip_chain_text_1');
        if (key === 'undefined') return t('scan_tab1_wizard_step5_tip_chain_text_2');

        return key;
    }

    const indicatorsGroupedByType = _.groupBy(allIndicators, 'type');
    const indicatorsKeys = _.keys(indicatorsGroupedByType);

    return (
        <>
            {
                isFetchingIndicators || !translationsLoaded ?
                    <TableSkeletonLoading
                        rows={15}
                        columns={8}
                        style={{ marginTop: 20 }}
                    /> :
                    <>
                        <table className="kpmg-table stripped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_5_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_6_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_7_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_8_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_13_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_9_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_10_a')}</span>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="table-head">
                                            <span style={{ whiteSpace: 'nowrap' }}>{t('settings_tab4_layout_chain_text_11_a')}</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="empty">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {
                                    indicatorsKeys.map((itemKey, i) => {
                                        const itemsData = indicatorsGroupedByType[itemKey];

                                        return itemsData.map((item, j) => {
                                            return (
                                                <tr key={j}>
                                                    <td style={{ textAlign: 'left', wordBreak: 'unset' }}>{item.name}</td>
                                                    <td className='cursor-pointer number-row' onClick={() => openInfoTextModal(item)}>
                                                        <BsPencilSquare style={{ fontSize: 14 }} />
                                                    </td>
                                                    <td className="input-cell number-row">
                                                        <input type="number" className='number-row' value={item.threshold} onChange={(e) => editIndicator(item.indicatorId, 'threshold', e.target.value)} />
                                                    </td>
                                                    <td className="input-cell number-row">
                                                        <input type="number" className='number-row' value={item.weight} onChange={(e) => editIndicator(item.indicatorId, 'weight', e.target.value)} />
                                                    </td>
                                                    <td className="input-cell number-row">
                                                        <select
                                                            value={item.valueType}
                                                            onChange={(e) => editIndicator(item.indicatorId, 'valueType', e.target.value)}>
                                                            <option value="Relative">{t('settings_tab4_indicator_type_file_relative')}</option>
                                                            <option value="Absolute">{t('settings_tab4_indicator_type_file_absolute')}</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <div className="radio-button cursor-pointer" onClick={() => editIndicator(item.indicatorId, 'kpiType', 'Complexity')} >
                                                            {
                                                                item.kpiType === "Complexity" &&
                                                                <div className="fill"></div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="radio-button cursor-pointer" onClick={() => editIndicator(item.indicatorId, 'kpiType', 'Criticality')}>
                                                            {
                                                                item.kpiType === "Criticality" &&
                                                                <div className="fill"></div>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="radio-button cursor-pointer" onClick={() => editIndicator(item.indicatorId, 'kpiType', 'NonAssigned')}>
                                                            {
                                                                item.kpiType === "NonAssigned" &&
                                                                <div className="fill"></div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    })
                                }
                            </tbody>
                        </table>

                        <div className="actions">
                            <div>{t('settings_tab4_button_layout_chain_text_a')}:</div>
                            <Button onClick={() => discardIndicatorsChanges()}>
                                {t('settings_tab4_button1_title_a')}
                            </Button>
                            <Button loading={isSubmitting} onClick={submitIndicators}>
                                {t('settings_tab4_button2_title_a')}
                            </Button>
                        </div>
                    </>
            }

            <InfoTextModal
                isOpen={infoTextModalOpen}
                setIsOpen={setInfoTextModalOpen}
                item={selectedItem}
                submitInfoCallback={submitInfoCallback}
            />
        </>
    )
};

export default IndicatorsComponent;