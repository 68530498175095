import React, { useState } from "react";
import NavTabs from 'components/common/NavTabs/NavTabs';
import TableSkeletonLoading from 'components/common/TableSkeletonLoading/TableSkeletonLoading';
import TablePagination from 'components/common/TablePagination/TablePagination';
import TableActionButton from 'components/common/TableActionButton/TableActionButton';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import Layout from 'layouts/layout';
import { useTranslation } from "react-i18next";
import { ImSearch } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import { GiAlarmClock } from 'react-icons/gi';
import { GrUndo, GrCheckmark } from 'react-icons/gr';
import { parseFileOverviewModalMetadata } from 'services/helper';
import moment from 'moment';
import './InventarisierungenComponent.scss';

const InventarisierungenComponent = (props) => {
    const {
        loading,
        inventoryFormOpen,
        inventoryFormPending,
        inventoryFormClosed,
        changeInventoryStatus,
        selectedTab,
        setSelectedTab,
        settings,
        deleteInventory,
        pagination,
        changePageData,
        sendEmailAction
    } = props;

    const { t } = useTranslation();

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const openFileOverviewModal = (row) => {
        const item = parseFileOverviewModalMetadata(row)
        const itemWithAllInventorySections = {
            ...item,
            inventoryListAll: true
        }
        setSelectedItem(itemWithAllInventorySections);
        setIsFileOverviewModalOpen(true);
    }

    const isAllowedAlias = settings?.validationConfig?.allowAlias === 1;
    const isAllowedValidation = settings?.validationConfig?.inventoryAutoClose === 1;

    const tabs = isAllowedValidation ?
        [t('screenInventarisierungen_tab_1'), t('screenInventarisierungen_tab_4')] :
        [t('screenInventarisierungen_tab_1'), t('screenInventarisierungen_tab_3'), t('screenInventarisierungen_tab_4')]

    return (
        <Layout>
            <div className="inventarisierungen-wrapper">
                <div className="inventarisierungen-content">
                    <NavTabs
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        tabs={tabs}
                    >
                        {
                            selectedTab === 0 &&
                            <div>
                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            inventoryFormOpen.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            <th>{t('screenValidierung_table_column_11')}</th>
                                                            <th>{t('screenValidierung_table_column_12')}</th>
                                                            <th>{t('screenValidierung_table_column_9')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            inventoryFormOpen.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    <td>{thisRow.businessOfficer || "-"}</td>
                                                                    <td>{thisRow.riskOfficer || "-"}</td>
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <TableActionButton style={{ fontSize: 14 }} onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </TableActionButton>

                                                                            <TableActionButton asyncFunction={() => sendEmailAction("2", thisRow.riskOfficer)}>
                                                                                <GiAlarmClock />
                                                                            </TableActionButton>

                                                                            <TableActionButton onClick={() => deleteInventory(thisRow.inventoryId)}>
                                                                                <MdClose />
                                                                            </TableActionButton>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>

                                <TablePagination
                                    pagination={pagination}
                                    dataCount={inventoryFormOpen.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }

                        {
                            selectedTab === (isAllowedValidation ? null : 1) &&
                            <div>

                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            inventoryFormPending.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            <th>{t('screenValidierung_table_column_11')}</th>
                                                            <th>{t('screenValidierung_table_column_12')}</th>
                                                            <th>{t('screenValidierung_table_column_9')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            inventoryFormPending.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    <td>{thisRow.businessOfficer || "-"}</td>
                                                                    <td>{thisRow.riskOfficer || "-"}</td>
                                                                    <td>
                                                                        <div className="action-buttons">
                                                                            <div className="table-btn magnifier" onClick={() => openFileOverviewModal(thisRow)}>
                                                                                <ImSearch />
                                                                            </div>
                                                                            <div className="table-btn arrow" onClick={() => changeInventoryStatus(thisRow.inventoryId, "open")}>
                                                                                <GrUndo />
                                                                            </div>
                                                                            <div className="table-btn arrow" style={{ fontSize: 14 }} onClick={() => changeInventoryStatus(thisRow.inventoryId, "closed")}>
                                                                                <GrCheckmark />
                                                                            </div>
                                                                            <div className="table-btn arrow" onClick={() => deleteInventory(thisRow.inventoryId)}>
                                                                                <MdClose />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>
                                <TablePagination
                                    pagination={pagination}
                                    dataCount={inventoryFormPending.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }

                        {
                            selectedTab === (isAllowedValidation ? 1 : 2) &&
                            <div>
                                <div className="overflow-x">
                                    {
                                        loading ?
                                            <TableSkeletonLoading
                                                columns={5}
                                                rows={5}
                                            /> :
                                            inventoryFormClosed.data.length ?
                                                <table className="kpmg-table secondary nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('screenValidierung_table_column_1')}</th>
                                                            {
                                                                isAllowedAlias &&
                                                                <th>{t('screenValidierung_table_column_10')}</th>
                                                            }
                                                            <th>{t('screenValidierung_table_column_2')}</th>
                                                            <th>{t('screenValidierung_table_column_4')}</th>
                                                            <th>{t('screenValidierung_table_column_11')}</th>
                                                            <th>{t('screenValidierung_table_column_12')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            inventoryFormClosed.data.map((thisRow, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
                                                                            {
                                                                                thisRow.type === 'yellow' ?
                                                                                    <img src={require('assets/icons/table/table-icon-1.png').default} alt="table-yellow-icon" width="13" /> :
                                                                                    <img src={require('assets/icons/table/table-icon-2.png').default} alt="table-blue-icon" width="13" />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        isAllowedAlias &&
                                                                        <td>{thisRow.alias || "-"}</td>
                                                                    }
                                                                    <td>{(thisRow.createdAt && moment(thisRow.createdAt).format('DD.MM.YY')) || '-'}</td>
                                                                    <td>{thisRow.fileName || "-"}</td>
                                                                    <td>{thisRow.businessOfficer || "-"}</td>
                                                                    <td>{thisRow.riskOfficer || "-"}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table> :
                                                <p>{t('screenValidierung_tab_1_empty')}</p>
                                    }
                                </div>
                                <TablePagination
                                    pagination={pagination}
                                    dataCount={inventoryFormClosed.count}
                                    changePageDataCallback={changePageData}
                                />
                            </div>
                        }
                    </NavTabs>
                </div>
            </div>

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                hideSnapshotsPagination={true}
            />
        </Layout>
    )
};

export default InventarisierungenComponent;