import React, { useState, useEffect } from "react";
import Layout from 'layouts/layout';
import NavTabs from 'components/common/NavTabs/NavTabs';
import EucCheckSuccess from '../../EucCheckSuccess/EucCheckSuccess'
import InventoryQuestionnaire from 'components/InventoryQuestionnaire/containers/InventoryQuestionnaire';
import { useTranslation } from "react-i18next";
import './InventoryQuestionsComponent.scss';

const InventoryQuestionsComponent = (props) => {
    const {
        formSent,
        filename,
        alias,
        successStatus,
        sendQuestionsData,
        previewTheme,
        history,
        isRiskOfficer
    } = props;

    const { t } = useTranslation();

    return (
        <Layout previewTheme={previewTheme}>
            <div className="inventory-check-wrapper">
                <div className="inventory-check-content">
                    <h2>{t('screenInventoryQuestions_title')}</h2>
                    <p style={{ marginBottom: 20 }}>{t('screenInventoryQuestions_description')}</p>

                    <NavTabs
                        selectedTab={0}
                        setSelectedTab={() => null}
                        tabs={[t('screenInventoryQuestions_tab')]}
                    >
                        <div className="inventory-questions-tab">
                            {
                                formSent ?
                                    <EucCheckSuccess
                                        filename={filename}
                                        alias={alias}
                                        statusText={isRiskOfficer ? t('screenEucCheck_layout_chain_riskOfficer_status') : t('screenEucCheck_layout_businessOfficer_status')}
                                        bottomText={
                                            successStatus === "euc" ?
                                                t('screenEucCheck_layout_chain_text_7') :
                                                t('screenEucCheck_layout_chain_text_13')
                                        }
                                        clickAction={() => history.push('/guidance/euc-check')}
                                    /> :
                                    <InventoryQuestionnaire submitForm={sendQuestionsData} />
                            }
                        </div>
                    </NavTabs>
                </div>
            </div>
        </Layout>
    )
};

export default InventoryQuestionsComponent;
