import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InventoryQuestionnaireComponent from '../components/InventoryQuestionnaireComponent';
import { connect } from "react-redux";
import { getInventoryQuestionsService, getInventorySectionQuestionsService, fileUploadService, getInventoryAllSectionsAnswersService, rejectInventoryService } from 'services/inventory';
import { confirm } from 'components/modals/ConfirmModal/ConfirmModalFunctions'
import { checkIfQuestionIsVisible } from 'services/inventoryQuestionsHelper';
import _ from 'lodash';
import * as Yup from 'yup';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const InventoryQuestionnaire = (props) => {
    const { submitForm, settings, user, location, allowEditAll, history } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isAllowedAlias, setIsAllowedAlias] = useState(null);
    const [isAllowedFileExplorer, setIsAllowedFileExplorer] = useState(null);

    const [editingConfigSectionId, setEditingConfigSectionId] = useState([]);
    const [oldQuestionsData, setOldQuestionsData] = useState([]);
    const [sectionsData, setSectionsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [yupValidationSchema, setYupValidationSchema] = useState({});
    const [formikInitialState, setFormikInitialState] = useState({});
    const [disabledSectionOrder, setDisabledSectionOrder] = useState([]);

    const [currentInventoryVersion, setCurrentInventoryVersion] = useState(null);
    const [isLoadingReject, setIsLoadingReject] = useState(false);

    const sectionId = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        const urlParams = location?.search?.split("?inventoryId=");
        const inventoryId = urlParams[1];
        initializeQuestionnaire(inventoryId);
    }, [location]);

    useEffect(() => {
        setIsAllowedAlias(settings?.validationConfig?.allowAlias === 1);
        setIsAllowedFileExplorer(settings?.validationConfig?.useFolderImpersonation === 1);
    }, [settings]);

    const initializeQuestionnaire = async (inventoryId) => {
        setIsLoading(true);

        let userAnswers;
        let sectionsQuestions;

        if (inventoryId) {
            const response = await getUserInventoryData(inventoryId);

            userAnswers = response?.userSectionsAnswers || [];
            sectionsQuestions = response?.sectionsQuestions || [];
        } else {
            sectionsQuestions = await getInventoryQuestions();
        }

        generateFormikData(sectionsQuestions, userAnswers);
    }

    const getUserInventoryData = async (inventoryId) => {
        try {
            const userSectionsAnswers = await getInventoryAllSectionsAnswersService(inventoryId);

            const sectionsQuestionsPromises = userSectionsAnswers.map(async sectionData => {
                const formConfigSectionId = sectionData.configSectionId;
                const sectionQuestions = await getInventorySectionQuestionsService(formConfigSectionId);
                return sectionQuestions;
            });

            const sectionsQuestions = await Promise.all(sectionsQuestionsPromises);
            const visibleSectionData = sectionsQuestions.filter(sectionData => {
                const thisSectionData = userSectionsAnswers.filter(section => section.configSectionId === sectionData.sectionId)[0];
                return thisSectionData.status !== "WaitingPrevious";
            });

            const visibleSectionsOrdered = _.orderBy(visibleSectionData, 'order', 'asc');

            setSectionsData(visibleSectionsOrdered);
            setOldQuestionsData(userSectionsAnswers);

            const disabledSectionEntries = sectionsQuestions.filter(sectionData => {
                const thisSectionData = userSectionsAnswers.filter(section => section.configSectionId === sectionData.sectionId)[0];
                const userCanEdit = user.samAccountName === thisSectionData.userName;
                const editableStatus = ["Open", "Started"].includes(thisSectionData.status);

                return !(userCanEdit && editableStatus);
            })

            let disabledSections = [];

            if (!allowEditAll) {
                disabledSections = disabledSectionEntries.map(sectionData => {
                    return sectionData.order;
                });

                setDisabledSectionOrder(disabledSections);
            }

            const sectionToSubmit = visibleSectionsOrdered.filter(sectionData => {
                return !disabledSections.includes(sectionData.order)
            });

            const configSectionToSubmit = sectionToSubmit.map(section => section.sectionId);
            setEditingConfigSectionId(configSectionToSubmit);

            const inventoryVersionData = userSectionsAnswers.filter(section => section.configSectionId === configSectionToSubmit[0])[0];
            setCurrentInventoryVersion(inventoryVersionData);

            return { userSectionsAnswers, sectionsQuestions: visibleSectionsOrdered };
        } catch (err) {
            console.log(err)
        }
    }

    const getInventoryQuestions = async () => {
        try {
            const response = await getInventoryQuestionsService();
            const availableSections = response.filter(section => section.order === 1);
            setSectionsData(availableSections);
            setEditingConfigSectionId([availableSections[0].sectionId]);

            return availableSections;
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    const uploadFilesToServer = async fileList => {
        try {
            const response = await fileUploadService(fileList);
            const fileUrls = response.map(fileUrl => fileUrl.key);
            return fileUrls;
        } catch (err) {
            console.log(err)
        }
    }

    const generateFormikData = (sectionsQuestions = [], userAnswers) => {
        let newFormikInitialState = {}

        let yupValidationSchemaTemp = {}

        const businessSection = sectionsQuestions.filter(section => section.sectionName === 'EUC Information')[0];

        if (businessSection) {
            const sectionWithAnswers = userAnswers?.filter(userAnswer => userAnswer.configSectionId === businessSection.sectionId)[0];

            newFormikInitialState = {
                fileSelect: JSON.stringify({ name: sectionWithAnswers?.fileName, type: sectionWithAnswers?.type }) || "",
                directory: {
                    path: sectionWithAnswers?.fileFullName || ""
                },
                alias: sectionWithAnswers?.alias || "",
            }

            yupValidationSchemaTemp = {
                fileSelect: Yup.string().required(t('screenEucCheck_check_chain_text_1')),
                directory: Yup.object().shape({
                    path: Yup.string().required(t('screenEucCheck_check_chain_text_1'))
                }),
                alias: Yup.string(),
            }
        }

        sectionsQuestions.forEach(section => {
            const sectionId = section.order;
            const sectionWithAnswers = userAnswers?.filter(userAnswer => userAnswer.configSectionId === section.sectionId)[0];

            let sectionToSend = true;

            if (sectionWithAnswers) {
                const userCanEdit = user?.samAccountName === sectionWithAnswers?.userName;
                const editableStatus = ["Open", "Started"].includes(sectionWithAnswers?.status);
                sectionToSend = userCanEdit && editableStatus;
            }

            section.questions.forEach(question => {
                const previousAnswerData = sectionWithAnswers?.answer?.filter(oldQuestion => oldQuestion.questionOrder === question.order)[0];
                const previousAnswer = previousAnswerData?.answer;

                const questionId = question.order.split(".").join("*");
                yupValidationSchemaTemp[`${sectionId}/${questionId}`] = Yup.string();
                if (question.required && sectionToSend) yupValidationSchemaTemp[`${sectionId}/${questionId}`] = Yup.string().required(t('screenEucCheck_check_chain_text_2'));

                newFormikInitialState[`${sectionId}/${questionId}`] = previousAnswer?.length ? previousAnswer : '§§§';
            })
        });

        yupValidationSchemaTemp = Yup.object().shape({
            ...yupValidationSchemaTemp
        });

        setYupValidationSchema(yupValidationSchemaTemp);
        setFormikInitialState(newFormikInitialState);

        setIsLoading(false);
    }

    const parseFormikQuestions = async (formValues, currentConfigSectionId) => {
        let formData = _.cloneDeep(formValues);

        const formDataKeys = _.keys(formData);
        let keysToRemove = [];

        formDataKeys.forEach(key => {
            const keyItems = key.split("/");
            const sectionOrder = parseInt(keyItems[0]);
            const questionOrder = keyItems[1];
            const questionOrderDot = questionOrder.split("*").join(".");
            const sectionData = sectionsData.filter(section => section.order === sectionOrder)[0];
            const question = sectionData.questions.filter(question => question.order === questionOrderDot)[0];

            const isQuestionVisible = checkIfQuestionIsVisible(formData, question, sectionOrder);

            const sectionToSendData = sectionsData.filter(section => section.sectionId === currentConfigSectionId)[0];
            const sendDataSectionOrder = sectionToSendData.order;
            const isQuestionFromOtherSection = sendDataSectionOrder !== sectionOrder;

            if (!isQuestionVisible || isQuestionFromOtherSection) {
                keysToRemove.push(`${sectionOrder}/${questionOrder}`);
            }
        });

        keysToRemove.forEach(keyToRemove => {
            delete formData[keyToRemove]
        });

        const finalFormDataKeys = _.keys(formData);

        const arrayOfAnswersPromises = finalFormDataKeys.map(async formDataKey => {
            const questionOrder = formDataKey.split("/")[1];
            const questionOrderDot = questionOrder.split("*").join(".");
            let formDataValue = formData[formDataKey];

            if (Array.isArray(formDataValue)) {
                if (formDataValue[0].file) {
                    formDataValue = await uploadFilesToServer(formDataValue);
                }

                formDataValue = formDataValue.filter(value => value !== "");
            }

            return {
                questionOrder: questionOrderDot,
                answer: typeof formDataValue === 'string' ? [formDataValue] : formDataValue
            }
        });

        const arrayOfAnswers = await Promise.all(arrayOfAnswersPromises);

        return arrayOfAnswers;
    }

    const sendQuestionsData = async (formValues, isDraft) => {
        setIsSubmitting(true);

        try {
            if (!formValues.directory.path) {
                toast(<ToastrContent type="danger" title={t('toastr_title_error')} message={t('toastr_inventory_questions_file_missing')} />, {
                    progressClassName: "toastr-progress-bar danger"
                });
            } else {
                let formData = {};
                let allAnswers = formValues;
                if (sectionId.current) formData.sectionId = sectionId.current;

                const isSendingBusinessSection = sectionsData.filter(section => {
                    return section.sectionName === 'EUC Information'
                })[0];

                if (isSendingBusinessSection) {
                    const pathReplacingSlashes = formValues.directory.path.split("/").join("\\");
                    const fileNameFromPath = _.last(pathReplacingSlashes.split("\\"));
                    const directoryWithoutFilename = pathReplacingSlashes.replace(`\\${fileNameFromPath}`, "");

                    allAnswers = _.omit(formValues, ['fileSelect', 'directory', 'alias']);

                    formData.directory = directoryWithoutFilename;
                    formData.fileName = fileNameFromPath;
                    formData.alias = formValues.alias;

                    const protectionRequirementQuestion = getQuestionByIdentifier(isSendingBusinessSection.questions, "protection-requirement")

                    if (protectionRequirementQuestion) {
                        const answerAlias = formValues[`1/${protectionRequirementQuestion.order}`];
                        const answerAliasValue = Array.isArray(answerAlias) ? answerAlias[0] : answerAlias;
                        const answer = protectionRequirementQuestion.options.filter(option => {
                            return option.order === answerAliasValue;
                        })[0];

                        formData.protectionRequirement = answer?.value?.en;
                    }

                    const technologiesQuestion = getQuestionByIdentifier(isSendingBusinessSection.questions, "technology")

                    if (technologiesQuestion) {
                        const answersOrderValues = formValues[`1/${technologiesQuestion.order}`];
                        if (answersOrderValues?.length > 0) {
                            const answerValues = answersOrderValues?.map(answerOrder => {
                                const answer = technologiesQuestion.options.filter(option => {
                                    return option.order === answerOrder;
                                })[0];

                                return `\\"${answer?.value?.en}\\"`;
                            });

                            formData.technologies = answerValues?.join(",");
                        }
                    }
                }

                const isSendingRiskSection = sectionsData.filter(section => {
                    const currentConfigSectionId = editingConfigSectionId[0];
                    return section.sectionName === 'Risk evaluation' && section.sectionId === currentConfigSectionId;
                })[0];

                if (isSendingRiskSection) {
                    formData.directory = oldQuestionsData[0]?.fileFullName?.replace(oldQuestionsData?.fileName, "");
                    formData.fileName = oldQuestionsData[0]?.fileName;
                }

                const isRiskOfficer = isSendingRiskSection;

                // If is Inventory Manager editing
                if (allowEditAll) {
                    const newSectionsDataPromises = editingConfigSectionId.map(async configSectionId => {
                        const sectionData = oldQuestionsData.filter(section => section.configSectionId === configSectionId)[0];
                        const questionAnswers = await parseFormikQuestions(allAnswers, configSectionId);

                        return {
                            ...formData,
                            sectionId: sectionData?.sectionId,
                            answer: questionAnswers
                        }
                    });

                    const newSectionsData = await Promise.all(newSectionsDataPromises);
                    const sectionsToSend = newSectionsData.filter(sectionData => {
                        const oldSectionData = oldQuestionsData.filter(oldSection => oldSection.sectionId === sectionData.sectionId)[0];

                        if (oldSectionData) {
                            const sectionsAreEqual = _.isEqual(sectionData.answer, oldSectionData.answer);
                            return !sectionsAreEqual;
                        } else {
                            return true;
                        }
                    });

                    await submitForm(sectionsToSend, null, isRiskOfficer);
                } else {
                    // Is is normal user editing (business officer or risk officer)
                    const currentConfigSectionId = editingConfigSectionId[0];
                    const questionAnswers = await parseFormikQuestions(allAnswers, currentConfigSectionId);
                    formData.configSectionId = currentConfigSectionId;
                    formData.answer = questionAnswers;

                    // Answering to a already existing inventory process
                    if (!_.isNil(currentConfigSectionId) && oldQuestionsData.length) {
                        const { sectionId: formSectionId } = oldQuestionsData.filter(questionData => {
                            return questionData.configSectionId === currentConfigSectionId;
                        })[0];

                        if (formSectionId) formData.sectionId = formSectionId;
                    }

                    const response = await submitForm(formData, isDraft, isRiskOfficer);
                    console.log('response', response);
                    sectionId.current = response?.inventorySectionId;
                }
            }
        } catch (err) {
            throw err;
        }

        setIsSubmitting(false);
    }

    const rejectInventoryForm = async (inventoryId) => {
        if (await confirm({
            title: t('reject_inventory_confirm_title'),
            confirmation: t('reject_inventory_confirm_message'),
        })) {
            setIsLoadingReject(true);

            try {
                await rejectInventoryService(inventoryId);
                history.push("/guidance/meine-validierungen");
            } catch (err) {
                console.log(`Error while rejecting inventory form: ${err}`);
            }

            setIsLoadingReject(false);
        }
    }

    const getQuestionByIdentifier = (questions, identifier) => {
        return questions.filter(question => {
            return question.identifier === identifier
        })[0];
    }

    return <InventoryQuestionnaireComponent
        {...props}
        sectionsData={sectionsData}
        sendQuestionsData={sendQuestionsData}
        isLoading={isLoading}
        isAllowedAlias={isAllowedAlias}
        isAllowedFileExplorer={isAllowedFileExplorer}
        isSubmitting={isSubmitting}
        formikInitialState={formikInitialState}
        yupValidationSchema={yupValidationSchema}
        disabledSectionOrder={disabledSectionOrder}
        allowEditAll={allowEditAll}
        currentInventoryVersion={currentInventoryVersion}
        isLoadingReject={isLoadingReject}
        rejectInventoryForm={rejectInventoryForm}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
    user: store.userReducer.user
});

export default withRouter(connect(mapStateToProps)(InventoryQuestionnaire));