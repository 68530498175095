import React, { useState } from "react";
import Layout from 'layouts/layout';
import { ImInfo } from 'react-icons/im';
import ArrowButton from 'components/common/ArrowButton/ArrowButton';
import { useTranslation } from "react-i18next";
import ReactHtmlParser from 'react-html-parser';
import { adjustColorBrightness } from 'services/helper';
import './EucCheckComponent.scss';

const EucCheckComponent = (props) => {
    const {
        previewTheme,
        theme: { secondaryColor },
        history
    } = props;

    const usedSecondaryColor = previewTheme ? previewTheme.secondaryColor : secondaryColor;
    const { t } = useTranslation();

    return (
        <Layout previewTheme={previewTheme}>
            <div className="euc-check-wrapper">
                <div className="euc-check-onboarding-wrapper">
                    <h2>{t('screenEucCheckHome_layout_chain_text_1')}</h2>
                    <p>{t('screenEucCheckHome_layout_chain_text_2')}</p>

                    <div className="euc-check-onboarding">
                        <div className="euc-check-list" style={{ borderColor: usedSecondaryColor, backgroundColor: adjustColorBrightness(usedSecondaryColor, 0.75) }}>
                            <div className="list-title" style={{ backgroundColor: usedSecondaryColor }}>{t('screenEucCheckHome_layout_chain_text_3')}</div>

                            <div className="list-body">
                                <div>
                                    {ReactHtmlParser(t('screenEucCheckHome_layout_chain_text_4'))}
                                </div>
                            </div>
                        </div>

                        <div className="euc-check-accesses">
                            <p>{t('screenEucCheckHome_layout_chain_text_7')}</p>

                            <ArrowButton
                                onClick={() => history.push("/guidance/euc-check-questions")}
                                buttonTitle={t('screenEucCheckHome_button_chain_text_1')}
                                buttonText={t('screenEucCheckHome_button_chain_text_2')}
                                previewTheme={previewTheme}
                            />

                            <ArrowButton
                                style={{ marginTop: 50 }}
                                onClick={() => history.push("/guidance/inventory-check-questions")}
                                buttonTitle={t('screenEucCheckHome_button_2_chain_text_1')}
                                buttonText={t('screenEucCheckHome_button_2_chain_text_2')}
                                previewTheme={previewTheme}
                            />
                        </div>
                    </div>

                    <div className="info-component">
                        <div className="info-icon" style={{ color: secondaryColor }}>
                            <ImInfo />
                        </div>
                        <div className="info-text"><b>{t('screenEucCheckHome_layout_chain_text_5')}</b></div>
                    </div>

                    <div className="info-component">
                        <div className="info-icon" style={{ color: secondaryColor }}>
                            <ImInfo />
                        </div>
                        <div className="info-text"><b>{t('screenEucCheckHome_layout_chain_text_6')}</b></div>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default EucCheckComponent;