import {
  getInterfaceConfigService,
  getConfigValidationService,
  getConfigGraphService,
  getConfigCrawlerService,
} from "services/settings";
import { getCoordinatorsConfigService } from "services/email";

const getInterfaceConfig = async () => {
  const checkHexString = (hex) => (hex.includes("#") ? hex : `#${hex}`);

  let configLayoutId = null;
  let mainColor = "#0091DA";
  let secondaryColor = "#00338D";
  let backgroundFile = require("assets/images/background-kpmg.jpg").default;
  let logoFile = require("assets/images/logo-proventa.svg").default;

  try {
    const response = await getInterfaceConfigService();

    if (response) {
      configLayoutId = response.configLayoutId;
      mainColor = checkHexString(response.highlightDarkBackground);
      secondaryColor = checkHexString(response.highlightLightBackground);
      backgroundFile = response.backgroundFile;
      logoFile = response.logoFile;
    }

    return {
      configLayoutId,
      mainColor,
      secondaryColor,
      backgroundFile,
      logoFile,
    };
  } catch (err) {
    console.log(`Error ${err.status}: ${err.statusText}`);
  }
};

const getConfigValidation = async () => {
  try {
    const response = await getConfigValidationService();
    return response;
  } catch (err) {
    console.log(`Error ${err.status}: ${err.statusText}`);
  }
};

const getConfigGraph = async (dashboard) => {
  try {
    const response = await getConfigGraphService();

    const dashboardData = {
      ...dashboard,
      list: {
        ...dashboard.list,
        pagination: {
          ...dashboard.list.pagination,
          limit: response.pointLimit > 100 ? 100 : response.pointLimit,
        },
      },
    };

    return { response, dashboardData };
  } catch (err) {
    console.log(`Error ${err.status}: ${err.statusText}`);
  }
};

const getConfigCrawler = async () => {
  try {
    const response = await getConfigCrawlerService();
    return response;
  } catch (err) {
    console.log(`Error ${err.status}: ${err.statusText}`);
  }
};

const getCoordinatorsConfig = async () => {
  try {
    const response = await getCoordinatorsConfigService();
    return response;
  } catch (err) {
    console.log(`Error ${err.status}: ${err.statusText}`);
  }
};

//todo: create distinct reducer actions
export const getAllConfigurations = async (settings, dashboard) => {
  const theme = await getInterfaceConfig();
  const validationConfig = await getConfigValidation();
  const coordinatorConfig = await getCoordinatorsConfig();
  const configCrawler = await getConfigCrawler();
  const { response: configGraph, dashboardData } = await getConfigGraph(
    dashboard
  );

  const settingsData = {
    ...settings,
    theme,
    validationConfig,
    configGraph,
    configCrawler,
    coordinatorConfig,
  };

  return { settingsData, dashboardData };
};
