import React, { useState, useRef } from "react";
import { Formik, Form, ErrorMessage, Field } from 'formik';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import FileSelect from 'components/common/FileSelect/FileSelect';
import Button from 'components/common/Button/Button';
import InfoComponent from 'components/common/InfoComponent/InfoComponent';
import MultiSelect from 'components/common/MultiSelect/MultiSelect';
import QuestionnaireSkeleton from 'components/common/QuestionnaireSkeleton/QuestionnaireSkeleton';
import { checkIfQuestionIsVisible } from 'services/inventoryQuestionsHelper';
import FileTreeModal from 'components/modals/FileTreeModal/containers/FileTreeModal';
import { FaAngleDoubleRight } from 'react-icons/fa';
import './InventoryQuestionnaireComponent.scss';

const questionsFactory = (fieldName, question, t, language, formikProps, isSectionDisabled) => {
    const { setFieldTouched, setFieldValue, values } = formikProps;

    if (values[fieldName] === '§§§') setFieldValue(fieldName, '');

    switch (question.type) {
        case 'select':
            const optionsOrdered = _.orderBy(question.options, ['order'], 'asc');
            const value = values[fieldName];
            const selectValue = Array.isArray(value) ? value[0] : value;

            return (
                <select
                    onChange={(e) => setFieldValue(fieldName, e.target.value)}
                    onBlur={() => setFieldTouched(fieldName, true)}
                    name={fieldName}
                    value={selectValue}
                    disabled={isSectionDisabled}>
                    <option value="" defaultValue>--- {t('screenEucCheck_dropbox_chain_text_1')} ---</option>
                    {
                        optionsOrdered.map((questionOption, i) => (
                            <option key={i} value={questionOption.order}>
                                {questionOption.value[language]}
                            </option>
                        ))
                    }
                </select>
            )

        case 'checkbox':
            const checkboxOptionsOrdered = _.orderBy(question.options, ['order'], 'asc');

            return (
                <MultiSelect
                    options={checkboxOptionsOrdered}
                    placeholder="-- Select options --"
                    formik={formikProps}
                    name={fieldName}
                    disabled={isSectionDisabled}
                />
            )

        case 'text':
            return (
                <textarea
                    onChange={(e) => setFieldValue(fieldName, e.target.value)}
                    onBlur={() => setFieldTouched(fieldName, true)}
                    name={fieldName}
                    value={values[fieldName]}
                    cols="30"
                    rows={question.fieldSize}
                    disabled={isSectionDisabled}>
                </textarea>
            )

        case 'file':
            const inputValues = values[fieldName];
            const inputValuesLength = inputValues?.length;

            const inputText = inputValuesLength ? (inputValuesLength > 1 ? `${inputValuesLength} files` : `${inputValues[0]}`) : t('screenEucCheck_question_file_chain_text3')

            // If it is an object or array of objects, it is a formik object, then not uploaded to server (not downloadable)
            // If it is an array of strings it is downloadable
            const isDownloadable = values[fieldName] && values[fieldName][0] && typeof values[fieldName][0] === "string";

            return (
                <FileSelect
                    id={fieldName}
                    inputText={inputText}
                    buttonText={isDownloadable ? t('inventory_questionnaire_download_button') : t('settings_tab1_button2_title')}
                    onChange={fileObject => {
                        const files = Array.isArray(fileObject) ? fileObject : [fileObject];
                        setFieldValue(fieldName, files);
                    }}
                    downloadFiles={isDownloadable ? values[fieldName] : null}
                    disabled={isSectionDisabled}
                    multiple
                />
            )

        default: return null;
    }
}

/*
    The Formik is initialized with all questions retrieved from back-end;
    We are using the symbol "§§§" to represent the questions not visible, because all questions are set as required by default.
    So, taking into account that the library does not implement dynamic validations, this approach is used to solve that problem.
*/

const InventoryQuestionnaireComponent = (props) => {
    const {
        sendQuestionsData,
        sectionsData,
        oldFormData,
        isLoading,
        isSubmitting,
        settings: { language },
        isAllowedAlias,
        isAllowedFileExplorer,
        formikInitialState,
        yupValidationSchema,
        disabledSectionOrder,
        allowEditAll,
        user,
        currentInventoryVersion,
        isLoadingReject,
        rejectInventoryForm
    } = props;

    const [isFileTreeModalOpen, setIsFileTreeModalOpen] = useState(false);
    const formikRef = useRef(null);
    const { t } = useTranslation();
    const sectionTitles = [t('settings_inventory_questions_section_1'), t('settings_inventory_questions_section_2')];

    const questionsVisible = sectionsData.map(sectionData => {
        const visibleQuestions = sectionData?.questions.map(question => {
            return checkIfQuestionIsVisible(formikRef?.current?.values, question, sectionData.order)
        });

        const countBy = _.countBy(visibleQuestions, value => value);

        return countBy['true'] || 0;
    });

    const questionsVisibleCount = _.sum(questionsVisible);
    const isInventoryRole = user?.groupPermissions?.includes("EUCINVENTORY");

    return (
        <div className="questionnaire-component">
            {
                isLoading ?
                    <QuestionnaireSkeleton isAllowedAlias={isAllowedAlias} /> :
                    <Formik
                        innerRef={formikRef}
                        enableReinitialize
                        initialValues={formikInitialState}
                        validationSchema={yupValidationSchema}
                        onSubmit={(values) => sendQuestionsData(values, false)}>
                        {(formikProps) => {
                            const values = formikProps.values;

                            return (
                                <Form>
                                    {
                                        sectionsData.map((sectionData, i) => {
                                            let questionIndex = sectionData?.sectionName === 'EUC Information' ? 2 : 0;
                                            const isSectionDisabled = disabledSectionOrder.includes(sectionData.order);

                                            return (
                                                sectionData?.questions?.length ?
                                                    <div className="section-data-wrapper" key={i}>
                                                        <div className="section-title">{sectionTitles[sectionData.order - 1]}</div>
                                                        <div className="section-questions">
                                                            {
                                                                sectionData?.sectionName === 'EUC Information' &&
                                                                <div className="question">
                                                                    <div className="question-content">
                                                                        <label htmlFor="fileSelect">
                                                                            <div className="question-title">
                                                                                <strong>1. </strong>
                                                                                <span>{t('screenEucCheck_question_file_chain_text2')}</span>
                                                                            </div>
                                                                        </label>

                                                                        <div className="question-body">
                                                                            <div className="flex">
                                                                                <Field
                                                                                    name="directory.path"
                                                                                    onChange={e => {
                                                                                        formikProps.setFieldValue('directory.path', e.target.value);
                                                                                    }}
                                                                                    placeholder={isAllowedFileExplorer ? t('screenEucCheck_question_file_chain_text3') : ""}
                                                                                    disabled={oldFormData || isSectionDisabled}
                                                                                    readOnly={isAllowedFileExplorer ? true : false}
                                                                                />

                                                                                {
                                                                                    isAllowedFileExplorer &&
                                                                                    <Button disabled={oldFormData || isSectionDisabled} onClick={() => oldFormData ? null : setIsFileTreeModalOpen(true)}>{t('screenEucCheck_button_chain_text_2')}</Button>
                                                                                }
                                                                            </div>

                                                                            <div className="error-message">
                                                                                <ErrorMessage name="directory.path" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {
                                                                isAllowedAlias && sectionData?.sectionName === 'EUC Information' &&
                                                                <div className="question">
                                                                    <div className="question-content">
                                                                        <label htmlFor="fileSelect">
                                                                            <div className="question-title">
                                                                                <strong>2. </strong>
                                                                                <span>{t('screenEucCheck_question_alias_text')}</span>
                                                                            </div>
                                                                        </label>

                                                                        <div className="question-body">
                                                                            <div className="flex">
                                                                                <Field
                                                                                    name="alias"
                                                                                    disabled={isSectionDisabled}
                                                                                />

                                                                                <InfoComponent tooltipValue={t('screenEucCheck_info_alias_text')} />
                                                                            </div>

                                                                            <div className="error-message">
                                                                                <ErrorMessage name="alias" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {
                                                                sectionData.questions.map((question, j) => {
                                                                    const isSectionTitle = question.type === 'title';
                                                                    const isQuestionVisible = isSectionTitle ? true : checkIfQuestionIsVisible(values, question, sectionData.order);
                                                                    const fieldName = `${sectionData.order}/${question.order.split(".").join("*")}`;

                                                                    if (isQuestionVisible && !isSectionTitle) {
                                                                        questionIndex++;
                                                                    } else {
                                                                        if (values[fieldName] === "") formikProps.setFieldValue(fieldName, "§§§")
                                                                    }

                                                                    return (
                                                                        isQuestionVisible &&
                                                                        <div className={`question ${isSectionTitle ? "question-section" : ""}`} key={j}>
                                                                            {
                                                                                isSectionTitle ?
                                                                                    <div className="section-title-content">
                                                                                        <FaAngleDoubleRight className="section-title-icon" />
                                                                                        <div className="section-title-text">{question.text[language]}</div>
                                                                                        <InfoComponent tooltipValue={question.infoText[language]} />
                                                                                    </div> :
                                                                                    <div className="question-content">
                                                                                        <label htmlFor={i}>
                                                                                            <div className="question-title">
                                                                                                <strong>{questionIndex}.</strong>
                                                                                                <span>{question.text[language]}</span>
                                                                                            </div>
                                                                                        </label>

                                                                                        <div className="question-body">
                                                                                            <div className="flex">
                                                                                                {questionsFactory(fieldName, question, t, language, formikProps, isSectionDisabled)}

                                                                                                <InfoComponent tooltipValue={question.infoText[language]} />
                                                                                            </div>

                                                                                            <div className="error-message">
                                                                                                <ErrorMessage name={fieldName} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div> :
                                                    null
                                            )
                                        })
                                    }

                                    {
                                        questionsVisibleCount ?
                                            <div className="form-actions">
                                                {
                                                    isInventoryRole &&
                                                    ["Open", "Started"].includes(currentInventoryVersion?.status) &&
                                                    currentInventoryVersion?.orderNo > 1 &&
                                                    <Button
                                                        onClick={() => rejectInventoryForm(currentInventoryVersion?.inventoryId)}
                                                        style={{ minWidth: 100 }}
                                                        className="small light"
                                                        loading={isLoadingReject}>
                                                        {t('screenInventoryQuestions_resend')}
                                                    </Button>
                                                }

                                                {
                                                    !allowEditAll &&
                                                    <Button
                                                        onClick={() => sendQuestionsData(formikRef.current.values, true)}
                                                        style={{ minWidth: 100 }}
                                                        className="small light"
                                                        loading={isSubmitting}>
                                                        {t('screenInventoryQuestions_save')}
                                                    </Button>
                                                }

                                                <Button
                                                    style={{ minWidth: 100 }}
                                                    type="submit"
                                                    className="small light"
                                                    loading={isSubmitting}>
                                                    {t('screenInventoryQuestions_submit')}
                                                </Button>
                                            </div> :
                                            <div>{t('screenInventoryQuestions_empty')}</div>
                                    }

                                    <FileTreeModal
                                        isOpen={isFileTreeModalOpen}
                                        setIsOpen={setIsFileTreeModalOpen}
                                        selectFileCallback={(fileData) => {
                                            formikProps.setFieldValue('directory.path', fileData.path);
                                            formikProps.setFieldValue('fileSelect', JSON.stringify(fileData));
                                            formikProps.validateField("directory.path");
                                        }}
                                    />
                                </Form>
                            )
                        }}
                    </Formik>
            }
        </div>
    )
};

export default InventoryQuestionnaireComponent;