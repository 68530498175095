import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ManageInventoryQuestionnaireComponent from '../components/ManageInventoryQuestionnaireComponent';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateInventoryQuestionnaireService } from 'services/inventory';
import ToastrContent from "components/common/Toastr/Toastr";
import { toast } from "react-toastify";

const ManageInventoryQuestionnaire = (props) => {
    const { history } = props;
    const { t } = useTranslation();

    const sendQuestionsData = async (formData) => {
        try {
            // Run in sequence, not parallel
            for (const sectionData of formData)
                await updateInventoryQuestionnaireService(sectionData);

            toast(<ToastrContent type="success" title={t('toastr_title_success')} message={t('toastr_inventory_updated')} />, {
                progressClassName: "toastr-progress-bar success"
            });

            history.push("/management/inventory");
        } catch (err) {
            console.log(`Error ${err.status}: ${err.statusText}`);
        }
    }

    return <ManageInventoryQuestionnaireComponent
        {...props}
        sendQuestionsData={sendQuestionsData}
    />;
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings
});

export default withRouter(connect(mapStateToProps)(ManageInventoryQuestionnaire));