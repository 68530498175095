import React, { useState, useEffect } from "react";
import Layout from "layouts/layout";
import InventoryQuestionnaire from 'components/InventoryQuestionnaire/containers/InventoryQuestionnaire';
import { FiArrowLeft } from 'react-icons/fi';
import './ManageInventoryQuestionnaireComponent.scss';

const ManageInventoryQuestionnaireComponent = (props) => {
    const { history, sendQuestionsData } = props;

    return (
        <Layout className="manage-inventory-page">
            <div className="manage-inventory-page-wrapper">
                <div className="go-back" onClick={() => history.push("/management/inventory")}>
                    <FiArrowLeft />
                    <span>Back to Inventory Management List</span>
                </div>

                <div className="questionnaire-container">
                    <InventoryQuestionnaire
                        submitForm={sendQuestionsData}
                        allowEditAll
                    />
                </div>
            </div>
        </Layout>
    )
};

export default ManageInventoryQuestionnaireComponent;