import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiLayoutGridLine } from 'react-icons/ri';
import { FiDownload } from 'react-icons/fi';
import ScatterChartComponent from '../../ScatterChartComponent';
import FileOverviewModal from 'components/modals/FileOverviewModal/containers/FileOverviewModal';
import ChartPointModal from 'components/modals/ChartPointModal/containers/ChartPointModal';
import ChartPointListModal from 'components/modals/ChartPointListModal/containers/ChartPointListModal';
import SelectUserModal from 'components/modals/SelectUserModal/containers/SelectUserModal';
import InventoryUserPicker from 'components/modals/InventoryUserPicker/containers/InventoryUserPicker';
import DownloadCsvModal from 'components/modals/DownloadCsvModal/containers/DownloadCsvModal';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'components/common/Button/Button';
import BeatLoader from "react-spinners/BeatLoader";
import MultiSelect from "react-multi-select-component";
import _ from 'lodash';
import Number from 'components/common/Number/Number';
import './GraphenComponent.scss';

const GraphenComponent = (props) => {
    const {
        scatterChartData,
        settings,
        criticalityValue,
        setCriticalityValue,
        complexityValue,
        setComplexityValue,
        axisVisible,
        setAxisVisible,
        globalFilters,
        localFilters,
        isLocalFiltersActive,
        setLocalFilters,
        getChartDataWithFilters,
        isGraphLoading,
        setIsGraphLoading,
        topics,
        toggleChartCallback,
        chartDuplicateData,
        chartDataCount,
        allDataCount,
        loadingAllDataCount
    } = props;

    const [currentZoom, setCurrentZoom] = useState(1);
    const [showFilters, setShowFilters] = useState(false);

    const [selectedPoint, setSelectedPoint] = useState(null);
    const [pointsList, setPointsList] = useState([]);

    const [isFileOverviewModalOpen, setIsFileOverviewModalOpen] = useState(false);
    const [isSelectUserModalOpen, setIsSelectUserModalOpen] = useState(false);
    const [isInventoryValidationModalOpen, setIsInventoryValidationModalOpen] = useState(false);
    const [isChartPointModalOpen, setIsChartPointModalOpen] = useState(false);
    const [isChartPointListModalOpen, setIsChartPointListModalOpen] = useState(false);
    const [isDownloadCsvModalOpen, setIsDownloadCsvModalOpen] = useState(false);

    const [showQuadrantsSelector, setShowQuadrantsSelector] = useState(false);
    const [selectedQuadrant, setSelectedQuadrant] = useState("all");
    const [chartExtremes, setChartExtremes] = useState({
        xMin: 0,
        xMax: 100,
        yMin: 0,
        yMax: 100
    });

    const [showDuplicatesSelector, setShowDuplicatesSelector] = useState(false);

    const { t } = useTranslation();

    const mainColor = settings?.theme?.mainColor;

    const currentZoomCallback = (zoom) => {
        setCurrentZoom(zoom);
    }

    const changeFilter = (key, value, applyLocalFilters) => {
        let newLocalFilters = _.cloneDeep(localFilters);
        _.set(newLocalFilters, key, value);
        setLocalFilters(newLocalFilters);

        if (applyLocalFilters) {
            getChartDataWithFilters(newLocalFilters);
        }
    }

    const changeFilterStatus = (statusToChange) => {
        let newStatuses = localFilters.status;

        if (newStatuses.includes(statusToChange)) {
            _.pull(newStatuses, statusToChange)
        } else {
            newStatuses.push(statusToChange);
        }

        changeFilter("status", newStatuses);
    }

    const changeQuadrant = newQuadrant => {
        setSelectedQuadrant(newQuadrant);

        if (newQuadrant === "1") setChartExtremes({ xMin: 0, xMax: 22, yMin: 0, yMax: 17 });
        if (newQuadrant === "2") setChartExtremes({ xMin: 22, xMax: 100, yMin: 0, yMax: 17 });
        if (newQuadrant === "3") setChartExtremes({ xMin: 0, xMax: 22, yMin: 17, yMax: 100 });
        if (newQuadrant === "4") setChartExtremes({ xMin: 22, xMax: 100, yMin: 17, yMax: 100 });

        if (newQuadrant === "all") {
            setChartExtremes({ xMin: 0, xMax: 100, yMin: 0, yMax: 100 });
            setAxisVisible(true);
        } else {
            setAxisVisible(false);
        }

        setShowQuadrantsSelector(false);
    }

    return (
        <div className="graphen-wrapper" style={{ width: '100%', height: '100%' }}>
            <div className="chart-wrapper">
                <div className="chart-configuration">
                    <div className="chart-switch">
                        <span>{t('analysis_tab1_graphen_title')}:</span>
                        <div className="chart-type" style={{ color: mainColor }} onClick={toggleChartCallback}>
                            <RiLayoutGridLine style={{ fontSize: 18 }} />
                        </div>

                        <div className="chart-data-count">
                            <span className="title">{t('analysis_tab1_graphen_files_displayed')}:</span>
                            <span className="count" style={{ color: mainColor }}>
                                {
                                    isGraphLoading ?
                                        "..." :
                                        <Number value={chartDataCount || 0} />
                                }
                                &nbsp;
                                {t('analysis_tab1_graphen_of')}
                                &nbsp;
                                {
                                    loadingAllDataCount ?
                                        "..." :
                                        <Number value={allDataCount || 0} />
                                }
                                &nbsp;
                                {t('analysis_tab1_graphen_results')}
                            </span>
                        </div>
                    </div>
                    <div className="chart-options">
                        <div className="chart-option hoverable" onClick={() => setShowDuplicatesSelector(true)}>
                            <div className="title">Show:</div>
                            <div className="value" style={{ color: mainColor }}>
                                {
                                    JSON.parse(localFilters?.duplicates?.showOnlyDuplicates) ?
                                        t('analysis_tab1_graphen_duplicates_only_duplicates') :
                                        t('analysis_tab1_graphen_duplicates_show_all')
                                }
                            </div>
                            {
                                showDuplicatesSelector &&
                                <div className="custom-selection">
                                    <OutsideClickHandler onOutsideClick={() => setShowDuplicatesSelector(false)}>
                                        <label htmlFor="duplicatesSelect">{t('analysis_tab1_graphen_duplicates')}</label>
                                        <select
                                            name="duplicatesSelect"
                                            id="duplicatesSelect"
                                            value={localFilters?.duplicates?.showOnlyDuplicates}
                                            onChange={e => {
                                                changeFilter("duplicates.showOnlyDuplicates", JSON.parse(e.target.value), true);
                                                setShowDuplicatesSelector(false);
                                            }}>
                                            <option value="false">{t('analysis_tab1_graphen_duplicates_show_all')}</option>
                                            <option value="true">{t('analysis_tab1_graphen_duplicates_only_duplicates')}</option>
                                        </select>
                                    </OutsideClickHandler>
                                </div>
                            }
                        </div>

                        <div className="chart-option hoverable" style={{ marginLeft: 25 }} onClick={() => setShowQuadrantsSelector(true)}>
                            <div className="title">{t('analysis_tab1_graphen_selection')}:</div>
                            <div className="value" style={{ color: mainColor }}>
                                {
                                    selectedQuadrant === 'all' ?
                                        t('analysis_tab1_graphen_selection_value') :
                                        `${t('analysis_tab1_graphen_selection_single_value')} ${selectedQuadrant}`
                                }
                            </div>
                            {
                                showQuadrantsSelector &&
                                <div className="custom-selection">
                                    <OutsideClickHandler onOutsideClick={() => setShowQuadrantsSelector(false)}>
                                        <label htmlFor="quadrantSelect">{t('analysis_tab1_graphen_selection_label')}</label>
                                        <select
                                            name="quadrantSelect"
                                            id="quadrantSelect"
                                            value={selectedQuadrant}
                                            onChange={(e) => changeQuadrant(e.target.value)}>
                                            <option value="all">{t('analysis_tab1_graphen_selection_value')}</option>
                                            <option value="1">{t('analysis_tab1_graphen_selection_single_value')} 1</option>
                                            <option value="2">{t('analysis_tab1_graphen_selection_single_value')} 2</option>
                                            <option value="3">{t('analysis_tab1_graphen_selection_single_value')} 3</option>
                                            <option value="4">{t('analysis_tab1_graphen_selection_single_value')} 4</option>
                                        </select>
                                    </OutsideClickHandler>
                                </div>
                            }
                        </div>

                        <div className="chart-option hoverable" style={{ marginLeft: 25 }} onClick={() => setShowFilters(!showFilters)}>
                            <div className="title">{t('analysis_tab1_graphen_filter')}:</div>
                            <div className="value" style={{ color: mainColor }}>{isLocalFiltersActive ? t('analysis_tab1_graphen_filter_value2') : t('analysis_tab1_graphen_filter_value')}</div>
                        </div>

                        <div className="chart-option" style={{ marginLeft: 25 }}>
                            <div className="title">{t('analysis_tab1_graphen_zoom')}:</div>
                            <div className="value" style={{ color: mainColor }}>{Math.ceil(100 / currentZoom)}%</div>
                        </div>

                        <div
                            className={`chart-option button ${!chartDataCount ? "disabled" : ""}`}
                            style={{ marginLeft: 25 }}
                            onClick={() => setIsDownloadCsvModalOpen(true)}>
                            <FiDownload style={{ fontSize: 18 }} />
                            <span>{t('analysis_tab1_listenansicht_download_csv')}</span>
                        </div>
                    </div>
                    {
                        showFilters &&
                        <div className="filter-options">
                            <OutsideClickHandler onOutsideClick={() => setShowFilters(false)}>
                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_complexity')}</div>
                                    <div className="filter-input">
                                        <input
                                            type="number"
                                            style={{ width: 30, textAlign: 'center' }}
                                            value={localFilters.complexity.min}
                                            onChange={e => changeFilter("complexity.min", parseInt(e.target.value))}
                                        />
                                        <span>{t('analysis_tab1_graphen_filters_complexity_min')}</span>
                                    </div>
                                    <div className="filter-input">
                                        <input
                                            type="number"
                                            style={{ width: 30, textAlign: 'center' }}
                                            value={localFilters.complexity.max}
                                            onChange={e => changeFilter("complexity.max", parseInt(e.target.value))}
                                        />
                                        <span>{t('analysis_tab1_graphen_filters_complexity_max')}</span>
                                    </div>
                                </div>

                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_criticality')}</div>
                                    <div className="filter-input">
                                        <input
                                            type="number"
                                            style={{ width: 30, textAlign: 'center' }}
                                            value={localFilters.criticality.min}
                                            onChange={e => changeFilter("criticality.min", parseInt(e.target.value))}
                                        />
                                        <span>{t('analysis_tab1_graphen_filters_criticality_min')}</span>
                                    </div>
                                    <div className="filter-input">
                                        <input
                                            type="number"
                                            style={{ width: 30, textAlign: 'center' }}
                                            value={localFilters.criticality.max}
                                            onChange={e => changeFilter("criticality.max", parseInt(e.target.value))}
                                        />
                                        <span>{t('analysis_tab1_graphen_filters_criticality_max')}</span>
                                    </div>
                                </div>

                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_status')}</div>
                                    <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-1"
                                            checked={localFilters.status.includes("non-euc")}
                                            onChange={e => changeFilterStatus("non-euc")}
                                        />
                                        <label htmlFor="filter-1">{t('analysis_tab1_graphen_legend_1')}</label>
                                    </div>
                                    
                                    <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-2"
                                            checked={localFilters.status.includes("euc")}
                                            onChange={e => changeFilterStatus("euc")}
                                        />
                                        <label htmlFor="filter-2">{t('analysis_tab1_graphen_legend_2')}</label>
                                    </div>
                                    <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-3"
                                            checked={localFilters.status.includes("no-validation")}
                                            onChange={e => changeFilterStatus("no-validation")}
                                        />
                                        <label htmlFor="filter-3">{t('analysis_tab1_graphen_legend_3')}</label>
                                    </div>
                                    {/* <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-5"
                                            checked={localFilters.status.includes("in-validation")}
                                            onChange={e => changeFilterStatus("in-validation")}
                                        />
                                        <label htmlFor="filter-5">{t('analysis_tab1_graphen_legend_5')}</label>
                                    </div> */}
                                    {/* <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-6"
                                            checked={localFilters.status.includes("validated")}
                                            onChange={e => changeFilterStatus("validated")}
                                        />
                                        <label htmlFor="filter-6">{t('analysis_tab1_graphen_legend_6')}</label>
                                    </div> */}
                                    {/* <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-7"
                                            checked={localFilters.status.includes("in-inventory-process")}
                                            onChange={e => changeFilterStatus("in-inventory-process")}
                                        />
                                        <label htmlFor="filter-7">{t('analysis_tab1_graphen_legend_7')}</label>
                                    </div> */}
                                    {/* <div className="filter-input">
                                        <input
                                            type="checkbox"
                                            id="filter-8"
                                            checked={localFilters.status.includes("in-inventory-closed")}
                                            onChange={e => changeFilterStatus("in-inventory-closed")}
                                        />
                                        <label htmlFor="filter-8">{t('analysis_tab1_graphen_legend_8')}</label>
                                    </div> */}
                                </div>

                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_validationtype')}</div>
                                    <div className="filter-input">
                                        <MultiSelect
                                            className="custom-select"
                                            options={[
                                                {
                                                    label: t('analysis_tab1_graphen_filters_validationtype_option_1'),
                                                    value: 'Voluntary'
                                                },
                                                {
                                                    label: t('analysis_tab1_graphen_filters_validationtype_option_2'),
                                                    value: 'Requested'
                                                },
                                                {
                                                    label: t('analysis_tab1_graphen_filters_validationtype_option_3'),
                                                    value: 'Manual'
                                                },
                                            ]}
                                            value={localFilters.validationType}
                                            onChange={values => changeFilter("validationType", values)}
                                            overrideStrings={{
                                                selectSomeItems: t('analysis_tab1_graphen_filters_validationtype_placeholder')
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_topic')}</div>
                                    <div className="filter-input">
                                        <MultiSelect
                                            className="custom-select"
                                            options={topics}
                                            value={localFilters.topicId}
                                            onChange={values => changeFilter("topicId", values)}
                                            overrideStrings={{
                                                selectSomeItems: t('analysis_tab1_graphen_filters_topic_placeholder')
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="filter-section">
                                    <div className="filter-title">{t('analysis_tab1_graphen_filters_name')}</div>
                                    <div className="filter-input">
                                        <input
                                            type="text"
                                            placeholder={t('analysis_tab1_graphen_filters_name_placeholder')}
                                            onChange={e => changeFilter('nameSearch', e.target.value)}
                                            value={localFilters.nameSearch}
                                        />
                                    </div>
                                </div>

                                <div className="filter-section">
                                    <div className="apply-filters">
                                        <Button className="light small" onClick={() => {
                                            getChartDataWithFilters();
                                            setShowFilters(false);
                                        }}>
                                            {t('analysis_tab1_graphen_local_filters_apply')}
                                        </Button>
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        </div>
                    }
                </div>

                <div className="chart-content">
                    {
                        isGraphLoading &&
                        <div className="chart-loader">
                            <BeatLoader
                                size={14}
                                color={"#525662"}
                                loading={true}
                            />
                        </div>
                    }
                    <ScatterChartComponent
                        chartData={scatterChartData}
                        currentZoomCallback={currentZoomCallback}
                        setIsChartPointModalOpen={setIsChartPointModalOpen}
                        setSelectedPoint={setSelectedPoint}
                        criticalityValue={criticalityValue}
                        setCriticalityValue={setCriticalityValue}
                        complexityValue={complexityValue}
                        setComplexityValue={setComplexityValue}
                        axisVisible={axisVisible}
                        xAxisMinValue={chartExtremes.xMin}
                        xAxisMaxValue={chartExtremes.xMax}
                        yAxisMinValue={chartExtremes.yMin}
                        yAxisMaxValue={chartExtremes.yMax}
                        setPointsList={setPointsList}
                        setIsChartPointListModalOpen={setIsChartPointListModalOpen}
                        chartDuplicateData={chartDuplicateData}
                    />
                </div>
            </div>

            <FileOverviewModal
                isOpen={isFileOverviewModalOpen}
                setIsOpen={setIsFileOverviewModalOpen}
                setSelectedItem={setSelectedPoint}
                selectedItem={selectedPoint}
                refreshScreen={getChartDataWithFilters}
            />

            <ChartPointModal
                isOpen={isChartPointModalOpen}
                setIsOpen={setIsChartPointModalOpen}
                setIsFileOverviewModalOpen={setIsFileOverviewModalOpen}
                setIsFileValidationModalOpen={setIsSelectUserModalOpen}
                setIsInventoryValidationModalOpen={setIsInventoryValidationModalOpen}
                selectedPoint={selectedPoint}
                setIsGraphLoading={setIsGraphLoading}
                getChartDataWithFilters={getChartDataWithFilters}
            />

            <ChartPointListModal
                isOpen={isChartPointListModalOpen}
                setIsOpen={setIsChartPointListModalOpen}
                pointsList={pointsList}
                setPointsList={setPointsList}
                setIsChartPointModalOpen={setIsChartPointModalOpen}
                setSelectedPoint={setSelectedPoint}
            />

            <SelectUserModal
                isOpen={isSelectUserModalOpen}
                setIsOpen={setIsSelectUserModalOpen}
                selectedItems={[selectedPoint]}
                setIsGraphLoading={setIsGraphLoading}
                getChartDataWithFilters={getChartDataWithFilters}
                title={t('file_validation_modal_title')}
                description={t('file_validation_modal_content')}
            />

            {
                isInventoryValidationModalOpen &&
                <InventoryUserPicker
                    isOpen={isInventoryValidationModalOpen}
                    setIsOpen={setIsInventoryValidationModalOpen}
                    selectedItems={[selectedPoint]}
                    reloadTab={getChartDataWithFilters}
                />
            }

            <DownloadCsvModal
                isOpen={isDownloadCsvModalOpen}
                setIsOpen={setIsDownloadCsvModalOpen}
                globalFilters={globalFilters}
                localFilters={localFilters}
            />
        </div >
    )
};

export default GraphenComponent;