import React, { useState, useEffect } from "react";
import { FiChevronDown } from 'react-icons/fi';
import { HiCheck } from 'react-icons/hi';
import OutsideClickHandler from 'react-outside-click-handler';
import './MultiSelect.scss';

const MultiSelect = (props) => {
    const {
        placeholder,
        name,
        options,
        formik,
        disabled
    } = props;

    const [dropdownOpen, isDropdownOpen] = useState(false);

    const handleChange = valueChange => {
        let newInputValue = [...inputValue];

        if (newInputValue.includes(valueChange)) {
            const arrayWithoutValue = newInputValue.filter(thisInputValue => thisInputValue !== valueChange);
            newInputValue = arrayWithoutValue;
        } else {
            newInputValue = [...newInputValue, valueChange]
        }

        formik.setFieldValue(name, newInputValue);
    }

    const inputValue = formik?.values[name];

    return (
        <div className={`multi-select-component ${disabled ? "disabled" : ""}`}>
            <div className="multi-select-wrapper" onClick={() => isDropdownOpen(true)}>
                <div className="multi-select-input">

                    {
                        inputValue?.length ?
                            `${inputValue?.length} selected option(s)` :
                            placeholder
                    }
                </div>
                <div className="multi-select-arrow">
                    <FiChevronDown />
                </div>
            </div>
            {
                dropdownOpen &&
                <OutsideClickHandler onOutsideClick={() => {
                    isDropdownOpen(false);
                    formik.setFieldTouched(name, true);
                }}>
                    <div className="multi-select-dropdown">
                        {
                            options.map((option, i) => (
                                <div className="multi-select-option" key={i} onClick={() => handleChange(option.order)}>
                                    <div className={inputValue.includes(option.order) ? "checkbox checked" : "checkbox"}>
                                        {
                                            inputValue.includes(option.order) ?
                                                <HiCheck /> :
                                                null
                                        }
                                    </div>
                                    <div className="value">
                                        {option.value.en}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </OutsideClickHandler>
            }
        </div>
    )
}

export default MultiSelect